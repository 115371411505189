import { appApi, createJsonAccessToken } from "./apiInstance";

const apiUrl = process.env.REACT_APP_GATEWAY_URL;

const mainContext = 'talent';
const module = 'previous_job';

export const newPreviouJob = (talentId, workExperience, userId, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).post(`${apiUrl}${mainContext}/${talentId}/${module}/new?userId=${userId}`, workExperience);

export const editPreviouJob = (talentId, workExperience, userId, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).put(`${apiUrl}${mainContext}/${talentId}/${module}/edit?userId=${userId}`, workExperience);

export const getPreviouJobs = (talentId, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${apiUrl}${mainContext}/${talentId}/${module}`);

export const deletePreviouJob = (talentId, id, userId, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).delete(`${apiUrl}${mainContext}/${talentId}/${module}/${id}/delete?userId=${userId}`);