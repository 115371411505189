import { appApi, createJsonAccessToken } from "./apiInstance";

const apiUrl = process.env.REACT_APP_GATEWAY_URL;
const mainContext = 'referrals';

export const getReferrals = (accessToken, filter, dateFilter, page=0, size=10, sorter='createdDate', direction='desc') => {
    const filterParam = filter ? `&filter=${filter}` : '';
    const dateFilterParam = dateFilter && dateFilter.range ? `&startDate=${dateFilter.range.start}&endDate=${dateFilter.range.end}` : '';
    return appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${mainContext}?paginated=true&page=${page}&size=${size}&sortBy=${sorter}&sortDir=${direction}${filterParam}${dateFilterParam}`);
}

export const getReferral = (email, vacandyId, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${mainContext}/${email}/${vacandyId}`);