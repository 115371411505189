import styles from '../feedback.module.scss';
import feedbackStart from '../../../../assets/icons/feedback_start.svg';
import { FEEDBACK_DESC } from '../../../core/talent-module/hardcode';
import { defaultStringEmpty } from '../../../core/talent-module/helper';

export const FeedbackStarts = ({
    labelClassName,
    rate
}) => {
    const renderStarts = () => (
        <div className={ styles.stars }>
            <img className={ rate >= 1 ? styles.selected : '' } src={ feedbackStart } alt="" />
            <img className={ rate >= 2 ? styles.selected : '' } src={ feedbackStart } alt="" />
            <img className={ rate >= 3 ? styles.selected : '' } src={ feedbackStart } alt="" />
            <img className={ rate >= 4 ? styles.selected : '' } src={ feedbackStart } alt="" />
            <img className={ rate >= 5 ? styles.selected : '' } src={ feedbackStart } alt="" />
        </div>
    )

    const renderRevieww = () => (
        <div className={ styles.review }>
            <div className={ styles.feedback }>{ renderStarts() }</div>
        </div>
    )

    return (
        <div className={ styles.title }>
            <span className={ labelClassName }>{ defaultStringEmpty(FEEDBACK_DESC[rate - 1]) }</span>
            { renderRevieww() }
        </div>
    )
}
