import { useContext } from 'react';
import styles from './navbar.module.scss';
import { AppContext } from '../../pages/candidates/context/AppProvider';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow_down.svg';

export const Navbar = () => {

    const appContext = useContext(AppContext);

    const onClickNavigation = (objNav, index) => {
        if( objNav.component ) {
            appContext.setMenuOptSelected(objNav.label);
            appContext.setMenuNavigation([{ label: objNav.label, component: objNav.component }]);
            appContext.setHomeContent(objNav.component);
        } else if ( objNav.onEvent ) {
            appContext.setMenuNavigation(appContext.menuNavigation.slice(0, index+1));
            if( objNav.arg ) {
                objNav.onEvent(objNav.arg);
            } else {
                objNav.onEvent();
            }
        } else {
            console.log('There is not click event')
        }
    }

    return (
        appContext.menuNavigation && appContext.menuNavigation.length > 1 && <div className={ styles.navigation }>
            {
                appContext.menuNavigation.map((o, index) => {
                    return index === appContext.menuNavigation.length-1 ? (
                        <label key={ `mnlbl${index}` } className={ styles.lastone }>{ o.label }</label>
                    ) : (
                        <div key={ `mndiv${index}` } className={ `${styles.label} ${o.component || o.onEvent ? styles.link : undefined} ${!appContext.showMenu && index === 0 ? styles.label_full : ''}` }>
                            <label key={ `mnlbl${index}` } onClick={ () => onClickNavigation(o, index) }>{ o.label }</label>
                            <ArrowIcon key={ `mnicon${index}` } />
                        </div>
                    )
                }) 
            }
        </div>
    )
}
