import { 
    useContext,
    useEffect,
    useState
} from 'react';
import styles from '../../candidateInfo.module.scss';
import addIcon from '../../../../../assets/icons/add_circle.svg';
import pencilIcon from '../../../../../assets/icons/blue_pencil.svg';
import trashIcon from '../../../../../assets/icons/trash.svg';
import { CandidateContext } from '../../context';
import { WorkExperienceModal } from './work_experience_modal';
import { objEquals, orderWorkExperiences, setStartAndEndDates } from '../../../../core/helper';
import { selectEmtpy, yearsHardCode, yearsOrderDesc } from '../../../../core/hardcode';
import { ModalDelete } from '../../../../components/modal/modal_delete';
import { getPreviouJobs, deletePreviouJob } from '../../../../api/previousJobClient';
import { getCatalog } from '../../../../api/catalogClient';
import { AppContext } from '../../context/AppProvider';

const WorkExperience = ({
    resumeViewFlag,
    renderBackButton
}) => {

    const appContext = useContext(AppContext);

    const [catSector, setCatSector] = useState([]);

    const {
        setWorkExperience,
        cleanWorkExperience,
        workExperiences, 
        setWorkExperiences,
        setWorkExperiencesCache
    } = useContext( CandidateContext );

    const loadWorkExperiences = (catSector) => {
        appContext.showLoading(true, appContext.t('loading'), styles.no_scroll);
        getPreviouJobs(appContext.talent.id, appContext.userData.token).then((response) => {
            if( response.data && response.data.code === 201 && response.data.result ) {
                let workExperiencesTmp = [];
                response.data.result.map( workExperience => {
                    const weWithDates = setSelectDates(workExperience);
                    weWithDates.period = buildPeriodStr(weWithDates);
                    const sector = catSector.find(s => s.id === workExperience.sectorId)
                    weWithDates.sector = sector && sector.id ? { id: sector.id, description: sector.optionName } : selectEmtpy
                    workExperiencesTmp.push(weWithDates);
                })
                const workExperiencesCurrents = workExperiencesTmp.filter( we => /*!we.endDate*/ we.stillWork );
                const workExperiencesLast = workExperiencesTmp.filter( we => /*we.endDates !== null*/ !we.stillWork );
                workExperiencesTmp = [ ...orderWorkExperiences(workExperiencesCurrents) ]
                workExperiencesTmp = [ ...workExperiencesTmp, ...orderWorkExperiences(workExperiencesLast) ]
                setWorkExperiences(workExperiencesTmp);
                setWorkExperiencesCache(workExperiencesTmp);
                appContext.showLoading(false, '', styles.no_scroll);
            }
        }).catch(() => {
            appContext.showLoading(false, '', styles.no_scroll);
        })
    }

    const deleteWorkExperience = workExperience => {
        deletePreviouJob(appContext.talent.id, workExperience.id, appContext.userData.userProfile.id, appContext.userData.token).then((response) => {
            if( response.data && response.data.code === 201 && response.data.result ) {
                setWorkExperiences( workExperiences.filter(we => !objEquals(we, workExperience) ) )
            }
        }).catch(() => {

        })
    }

    const setSelectDates = we => {
        const yearHardCode = yearsHardCode(yearsOrderDesc);
        return setStartAndEndDates(we, yearHardCode, yearHardCode);
    }

    const buildPeriodStr = workExperience => {
        const startDateStr = objEquals(workExperience.fiMes,selectEmtpy) ? appContext.t('noData.unspec') : `${workExperience.fiMes.description} ${workExperience.fiAnio.description}`;
        const endDateStr = workExperience.stillWork ? appContext.t('noData.present') : `${workExperience.ftMes.description} ${workExperience.ftAnio.description}`;
        const period = startDateStr === appContext.t('noData.unspec') && endDateStr === appContext.t('noData.present') ? appContext.t('candidate.resume.courses.notPeriod') : `${startDateStr} - ${endDateStr}`;
        return period;
    }

    const handleOnClickShowModal = (workExperience, index) => {
        if ( workExperience ) {
            setWorkExperience({
                ...workExperience,
                index: index
            });
        } else if (!workExperience) {
            cleanWorkExperience();
        }
        appContext.setModalContent( <WorkExperienceModal catSector={catSector} /> )
        appContext.setModalFlag(true)
    }

    const handleOnClickRemove = workExperience => {
        if( workExperience.id ) {
            deleteWorkExperience(workExperience);
        } else {
            setWorkExperiences( workExperiences.filter(we => !objEquals(we, workExperience)) )
        }
        appContext.setModalFlag(false);
    }

    useEffect(() => {
        getCatalog(1000020052, 0 ,100, '', appContext.userData.token).then(({status, data}) => {
            if( status === 202 && data && data.code === 202 && data.result && data.result.content && Array.isArray(data.result.content) ) {
                const sectorTmp = [];
                data.result.content.map( s => sectorTmp.push({ id: s.catalogItemId, optionName: s.valueItem }) )
                setCatSector(sectorTmp);
                loadWorkExperiences(sectorTmp);
            }
        }).catch(error => {
            console.log(error);
        });
    }, []);

    useEffect(() => {
        setWorkExperiences(orderWorkExperiences(workExperiences));
    }, [workExperiences])

    const showDeleteModal = index => {
        const workExperience = workExperiences[index];
        appContext.setModalContent(
            <ModalDelete
                title={ `${workExperience.position} - ${workExperience.employer}` }
                subtitle={ appContext.t('candidate.resume.we.deleteMsg1') }
                paragrahp={ appContext.t('candidate.resume.we.deleteMsg2') }
                onClick={ () => handleOnClickRemove(workExperience) }
                onClickCancel={ () => appContext.setModalFlag(false) }
            />
        )
        appContext.setModalFlag(true);
    }

    const renderWorkExperiences = () => (
		workExperiences && workExperiences.length > 0 && workExperiences.map((we, index) => {
            return (
                <div key={index} className={ styles.list_container }>
                    {/* index: {index} id: {we.id} */}
                    <div className={ styles.list_items }>
                        <div className={ styles.item_grouped }>
                            <label>{ `${we.position} - ${we.employer ? we.employer : appContext.t('noData.notset') } - ${appContext.t('candidate.resume.info.sector')}: ${we.sectorName || appContext.t('noData.notset')}` }</label>
                            <div className={ styles.action_container }>
                                <img src={ pencilIcon } alt="" width={ 24 } height={ 24 } onClick={ () => handleOnClickShowModal(we, index) } />
                                <img className={ styles.trash } src={ trashIcon } alt="" width={ 24 } height={ 24 } onClick={ () => showDeleteModal(index) } />
                            </div>
                        </div>
                        <label className={ styles.subtitle }>{ we.period }</label>
                        <p>{ we.description ? we.description : appContext.t('candidate.resume.we.noDesc') }</p>
                    </div>
                </div>
            )}
        )
	);

    const renderTitle = () => (<p className={ styles.title }>{ appContext.t('candidate.resume.we.title') }</p>);

    return (
        <div className={ styles.form_container }>
            { appContext.isDesktopOrLaptop ? renderTitle() : ( resumeViewFlag ? renderTitle() : renderBackButton(appContext.t('candidate.resume.we.title'))) }
            { renderWorkExperiences() }
            <div className={ `${styles.form_row} ${styles.padded}` }>
                <button className={ styles.add_info } onClick={() => handleOnClickShowModal(null)}>
                    { appContext.t('candidate.resume.we.labelAdd') }
                    <img src={ addIcon } alt="" />
                </button>
            </div>
        </div>
    )
}
export default WorkExperience;