import { useContext } from 'react';
import styles from './candidateInfo.module.scss';
import { AppContext } from './context/AppProvider';
import { InfoMessage, InfoMessageType } from '../talents/components/misc/InfoMessage';

export const CandidatePostulation = ({
    postulations
}) => {

    const appContext = useContext(AppContext);

    const getRecruimentColor = recruiment => {
        let colorVar = '';
        switch (recruiment) {
            case 1000200001:
            case 1000200002:
            case 1000200004:
            case 1000200005:
            case 1000200006:
            case 1000200007:
                colorVar= '--color-secondary'
                break;
            case 1000200008:
                colorVar= '--color-green'
                break;
            case 1000200009:
            case 1000200010:
                colorVar= '--color-error';
                break;
            case 1000200003:
                colorVar= '--color-recruiment-yellow';
                break;
            default:
                colorVar= '--color-recruiment-gray'
                break;
        }
        return `var(${colorVar})`;
    };

    const getRecruimentNum = recruiment => {
        let recNum = 0;
        switch (recruiment) {
            case 1000200001:
                recNum = 1;
                break;
            case 1000200002:
                recNum = 2;
                break;
            case 1000200004:
                recNum = 3;
                break;
            case 1000200005:
                recNum = 4;
                break;
            case 1000200006:
                recNum = 5;
                break;
            case 1000200007:
                recNum = 6;
                break;
            default:
                recNum = 7;
                break;
        }
        return recNum;
    };

    const renderRecruiment = recruiment => [...Array(7)].map((r, index) => (
        <div
            key={ index }
            style={{
                borderRadius: '2.5px',
                width: '5px',
                height: '16px',
                backgroundColor: getRecruimentColor( getRecruimentNum(recruiment)<=index ? 0 : recruiment)
            }}
        />
    ))

    const renderNoFound = () => {
        const title = appContext.t('candidate.resume.postulation.notFoundTitle');
        const subtitles = [`${appContext.talent.firstName} ${appContext.t('candidate.resume.postulation.notFoundDetail')}`];
        const cssParagraph = styles.pharagrap_simple;
        const type = InfoMessageType.notFound;
        return <InfoMessage title={ title } subtitles={ subtitles } type={ type } cssParagraph={ cssParagraph } />
    }

    const renderPoslutates = () => postulations.map((p, index) => (
        <div key={ index }>
            <div className={ styles.section_main }>
                <div className={ styles.section_header}>
                    <div className={ styles.section_title }>
                        <div className={styles.decorator} />
                    </div>
                    <label className={ styles.section_title }>{ `${p.id}: ${p.name}` }</label>
                    <div className={ styles.vertical_separator } />
                    { p.recruitmentDesc }
                    <div style={{ display: 'flex', gap: '4px' }}>{ renderRecruiment(p.recruitment) }</div>
                    <div className={ styles.vertical_separator } />
                    { p.client }
                    <div className={ styles.vertical_separator } />
                    { `${appContext.t('candidate.resume.postulation.recruiter')}: ${p.recruiter}` }
                </div>
            </div>
            <div className={ styles.section_header }>
                <label className={ styles.label_detail }>{appContext.t('candidate.resume.postulation.status')}: </label>{p.status}
            </div>
            <div className={ styles.section_header }>
                <label className={ styles.label_detail }>{appContext.t('candidate.resume.postulation.date')}: </label>{p.date}
            </div>
        </div>
    ))

    return (
        <div className={ styles.postulates_content }>
            { postulations && postulations.length > 0 ? renderPoslutates() : renderNoFound() }
        </div>
    )
}
