import styles from '../../checkup.module.scss';
import stylesC from '../../../candidates/candidateInfo.module.scss';
import { Radio } from '../../../../components/inputs/Radio';
import { BarEvaluation } from '../../../../components/inputs/BarEvaluation';
import { TextArea } from '../../../../components/inputs/TextArea';
import { ClimateFollowup } from './ClimateFollowup';

export const ClimateDetail = ({
    appContext,
    checkup,
    checkupErrors,
    onChangeInput,
    catClasification,
    catExistRisk,
    catLevelSatisfaction,
    modeResume
}) => {

    const onChangeSquare = ( id, value ) => {
        const commentsTmp = [ ...checkup.comments ];
        const index = commentsTmp.findIndex( c => c.id === id );
        commentsTmp[index].value = {
            ...commentsTmp[index].value,
            id: value
        }
        onChangeInput('comments', commentsTmp);
    }

    const onChangeComment = ( id, value ) => {
        const commentsTmp = [ ...checkup.comments ];
        const index = commentsTmp.findIndex( c => c.id === id );
        commentsTmp[index].value = {
            ...commentsTmp[index].value,
            comment: value
        }
        onChangeInput('comments', commentsTmp);
    }

    const renderComments = () => checkup.comments.map((c, index) => (
        <div key={ index } className={styles.section}>
            <div className={ styles.section_subtitle }><label className={ styles.size18 }>{ c.title }</label></div>
            <label className={ styles.question }>{ c.question }</label>
            <BarEvaluation
                classMain={ styles.section_bar }
                name={ c.id }
                value={ c.value }
                options={ catLevelSatisfaction }
                onClick={ onChangeSquare }
            />
            <div className={ styles.comment }>
                <TextArea
                    cssCounter={ styles.subtitle_left }
                    name={ c.id }
                    label={ `${appContext.t('checkUp.climate.commentLabel')}*` }
                    maxLength={ 3000 }
                    errorLen={ 'validator.length' }
                    value={ c.value?.comment || "" }
                    onChange={ onChangeComment }
                    error={ Object.keys(checkupErrors).includes(`comment_${index}`) ? checkupErrors[`comment_${index}`][0] : '' }
                    focus={ Object.keys(checkupErrors).includes(`comment_${index}_focus`) }
                />
            </div>
        </div>
    ))

    const renderClimateTitle = () => (
        <div className={ stylesC.section_header}>
            <div className={ stylesC.section_title }>
                <div className={ stylesC.decorator }></div>
                { appContext.t('checkUp.climate.title') }
            </div>
        </div>
    )

    return (
        <div className={ `${stylesC.form_container} ${styles.checkup}` }>
            { modeResume && <ClimateFollowup appContext={ appContext } checkup={ checkup } checkupErrors={ checkupErrors } onChangeInput={ onChangeInput } /> }
            { modeResume && checkup.followUps && renderClimateTitle() }
            <Radio
                name={ 'clasification' }
                value={ checkup.clasification }
                label={ appContext.t('checkUp.climate.clasificationLabel').replace(':name', appContext.talent.firstName) }
                className={ styles.section }
                options={ catClasification }
                onClick={ onChangeInput }
            />
            <Radio
                name={ 'existRisk' }
                value={ checkup.existRisk }
                label={ appContext.t('checkUp.climate.exitRiskLabel').replace(':name', appContext.talent.firstName) }
                className={ styles.section }
                options={ catExistRisk }
                onClick={ onChangeInput }
            />
            <div className={ styles.section }>
                <label className={ styles.title_orange }>{ appContext.t('checkUp.climate.opinionLabel') }</label>
                { checkup.comments && renderComments() }
            </div>
        </div>
    )
}
