import React, { useContext } from 'react'
import stylesH from '../pages/home/home.module.scss';
import styles from '../pages/talents/talentInfo.module.scss';
import Header from '../components/header/header';
import { InfoMessage, InfoMessageType } from '../pages/talents/components/misc/InfoMessage';
import { AppContext } from '../pages/candidates/context/AppProvider';
import { appApi } from '../api/apiInstance';

const apiUrl = process.env.REACT_APP_GATEWAY_URL;
const appName = process.env.REACT_APP_NAME;

export const ErrorFallback = () => {
    const appContext = useContext(AppContext);

    return (
        <div className={ stylesH.home_container }>
            <Header hideMenu hideUser />
            <div className={ stylesH.content }>
                <div className={ styles.container } >
                    <div className={ styles.wrapper } style={{ marginTop: '50px' }}>
                        <InfoMessage
                            title={ appContext.t('error.generic.title') }
                            subtitles={[
                                appContext.t('error.generic.detail1'),
                                appContext.t('error.generic.detail2')
                            ]}
                            type={ InfoMessageType.error }
                            cssParagraph={ styles.pharagrap_multiple } />
                    </div>
                </div>
            </div>
        </div>
    )
}

const saveLog = request => appApi(apiUrl).post('error-logs', request);

export const logError = (error, { componentStack }) => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const bodyRequest = {
        "applicationName": appName,
        "moduleName": appName,
        "errorCode": error.name,
        "errorMessage": error.message,
        "errorDetails": error.stack,
        "severity": "HIGH",
        "reportedBy": userInfo.userProfile.email,
        "status": "OPEN"
    }
    saveLog(bodyRequest).then(response => {
        console.log(response);
    }).catch(error => {
        console.log(error);
    })
}
