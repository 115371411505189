// Reference: https://www.npmjs.com/package/crypto-js
// Reference: https://code.google.com/archive/p/crypto-js/

import CryptoJS from "crypto-js";

const secretKey = process.env.REACT_APP_CRYPTO_KEY;
const ivKey = process.env.REACT_APP_CRYPTO_IV;

export const encryptString = input => {
    const key = CryptoJS.enc.Base64.parse(secretKey);
    const iv = CryptoJS.enc.Base64.parse(ivKey);
    const phraseDecrypt = CryptoJS.AES.encrypt(input, key, {iv: iv}).toString();
    return phraseDecrypt;
}

export const decryptString = input => {
    const key = CryptoJS.enc.Base64.parse(secretKey);
    const iv = CryptoJS.enc.Base64.parse(ivKey);
    const bytes = CryptoJS.AES.decrypt(input, key, {iv: iv});
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
}