import { appApi, createJsonAccessToken } from "./apiInstance";

const apiUrl = process.env.REACT_APP_GATEWAY_URL;
const mainContext = 'user';
const moduleAuth = 'auth';

export const getAllUsers = (accessToken, filter, roleId, clientFilter, paginated, page=0, size=10, sorter='userregistration.name', direction='asc') => {
    const paginatedParam = paginated ? `&paginated=true` : '';
    const filterParam = filter ? `&filter=${filter}` : '';
    const roleParam = roleId ? `&roleId=${roleId}` : '';
    let clientParam = '';
    if( clientFilter ) {
        clientFilter.forEach( c => clientParam += `&clientId=${c.id}` )
    }
    return appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${mainContext}/all?page=${page}&size=${size}&sortBy=${sorter}&direction=${direction}${paginatedParam}${filterParam}${roleParam}${clientParam}`);
}

export const getUser = (accessToken, userId) => appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${mainContext}/${userId}`);

// export const getBoardLink = (accessToken, userId) => appApi(apuUrl, createJsonAccessToken(accessToken)).get(`${mainContext}/${userId}/getBoardLink`);

export const doLogin = request => appApi(apiUrl).post(`${mainContext}/${moduleAuth}/login`,request);

export const forgotPassword = email => appApi(apiUrl).post(`${mainContext}/${moduleAuth}/forgotPassword?email=${email}`);

export const confirmForgotPassword = request => appApi(apiUrl).post(`${mainContext}/${moduleAuth}/confirmForgotPassword`, request);

export const havePermission = (accessToken, userId, permissionFilter) => {
    let permissionParam = '';
    if( permissionFilter ) {
        permissionFilter.forEach( (p, index) => permissionParam += `${ index === 0 ? '?' : '&'}permissionIds=${p}` )
    }
    return appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${mainContext}/${userId}/havePermission${permissionParam}`)
};