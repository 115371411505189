import { useContext, useEffect } from 'react';
import { Menu } from '../../components/menu/Menu';
import styles from './home.module.scss';
import Header from '../../components/header/header';
import {ReactComponent as ArrowMenuIcon } from '../../../assets/icons/arrow_menu.svg';
import { AppContext } from '../candidates/context/AppProvider';
import { Navbar } from '../../components/navbar/Navbar';
import CandidateInfo from '../candidates/candidateInfo';
import { getFeedback } from '../../api/feedbackClient';
import { setSelectDates } from '../feedback/FeedbackList';

export const Home = () => {

    const appContext = useContext(AppContext);

    const redirectToFeedback = () => {
        const feedbackData = JSON.parse(localStorage.getItem('feedbackData'));
        localStorage.removeItem('feedbackData');
        if (feedbackData) {
            getFeedback(feedbackData.userId, feedbackData.talentId, feedbackData.reviewId, appContext.userData.token).then(({status, data}) => {
                if( status === 200 && data.code === 201 ) {
                    appContext.setTalent({ id: feedbackData.talentId })
                    appContext.setMenuOptSelected(appContext.t('menu.talent.opt1'));
                    const review = { ...data.result }
                    const reWithDates = setSelectDates(review);
                    reWithDates.period = `${reWithDates.reviewDay} ${appContext.t('paginator.of')} ${reWithDates.reviewMes.description} ${reWithDates.reviewAnio.description}`;
                    appContext.setHomeContent(<CandidateInfo tab={8} resumeTab={1} feedback={reWithDates} showReport={feedbackData.checkupId ? true : false} />)
                }
            }).catch(error => {
                console.log(error);
            })
        }
    }

    useEffect(() => {
        // For check session
        // TODO For Public and Private Router
        if (!appContext.userData || !appContext.userData.hasOwnProperty('userProfile')) {
            localStorage.removeItem('userInfo');
            appContext.setUserData(null);
            appContext.navigate("/login");
        } else {
            redirectToFeedback();
        }

    }, [])

    const renderLoading = () => appContext.loadingData.flag && (
        <div className={ styles.loading_container }>
            <div className={ `${styles.spinner_container} ${appContext.showMenu ? styles.spinner_container_short : ''}` }>
                <div className={ styles.spinner }></div>
                <p>{ appContext.loadingData.loadingLabel }</p>
            </div>
        </div>
    )

    return (
        <div className={ styles.home_container }>

            { appContext.modalFlag && appContext.modal }
            <Header />
            { appContext.notification }
            { appContext.showMenu && 
                <div className={ styles.menu }>
                    <Menu />
                </div>
            }
            { !appContext.showMenu && 
                <div className={ styles.button_menu_hidden } onClick={ () => appContext.setShowMenu(!appContext.showMenu) }>
                    <div className={ `${styles.icon} ${ appContext.showMenu ? styles.svg : ''}` }>
                        <ArrowMenuIcon />
                    </div>
                </div>
            }
            <div className={ `${styles.content} ${!appContext.showMenu ? styles.content_full : ''}` }>
                { renderLoading() }
                { <Navbar /> }
                <div ref={ appContext.contentRef } className={ styles.content_scroll }>{ appContext.homeContent }</div>
            </div>
        </div>
    )
}
