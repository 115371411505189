import { buildString } from '../core/helper';
import { appApi, createJsonAccessToken } from './apiInstance';

const apiUrl = process.env.REACT_APP_GATEWAY_URL;

const mainContext = 'talent';
const catalogContext = 'catalog';
const skillModule = 'skills';
const profileModule = 'profile';
const userModule = 'user';

export const getSkills = (talentId, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${mainContext}/${talentId}/${skillModule}`);

export const saveAll = (talentId, request, userId, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).put(`${mainContext}/${talentId}/${skillModule}/save?userId=${userId}`, request);

export const getSkillsTag = (accessToken, filter, filterType, visibilityFilter, transFilter, page=0, size=10) => {
    //console.log('getSkillsTag', 'filter', filter, 'filterType', filterType, 'visibilityFilter', visibilityFilter, 'transFilter', transFilter);
    const filterParam = filter ? `&name=${filter}` : '';
    const publicParam = visibilityFilter.length === 1 ? `&isPublic=${ visibilityFilter.find( v => v.id === 1) ? 'true' : 'false' }` : '';
    const transParam = transFilter.length === 1 ? `&containsEnglishTranslation=${ transFilter.find( v => v.id === 1) ? 'true' : 'false' }` : '';
    let filterCategoryId = '';
    if( filterType.length > 0 ) {
        const valueString = [];
        filterType.forEach( v => valueString.push(v.id) );
        const categories = buildString(valueString).replaceAll(' ', '');
        filterCategoryId = `&categoryId=${categories}`;
    }
    return appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${catalogContext}/${skillModule}?page=${page}&size=${size}&sort=name&direction=ASC${filterCategoryId}${filterParam}${publicParam}${transParam}`);
}

export const getSkillTag = (skillTagId, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${catalogContext}/${skillModule}/${skillTagId}`);

export const getSkillTagByName = (skillTagName, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${catalogContext}/${skillModule}/findByName?wholeWord=true&name=${skillTagName.replaceAll(' ', '%20')}`);

export const persistSkillTag = (userId, skillTag, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).post(`${catalogContext}/${skillModule}/${userModule}/${userId}`, skillTag);

export const deleteSkillTag = (userId, skillTagId, forceDeletion, accessToken) => {
    return appApi(apiUrl, createJsonAccessToken(accessToken)).delete(`${catalogContext}/${skillModule}/${skillTagId}?forceDeletion=${forceDeletion}`);
}

export const deleteSkillTagValidation = (skillTagId, accessToken) => {
    return appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${catalogContext}/${skillModule}/${skillTagId}/delete-validation`);
}

export const getSkillProfiles = (accessToken, isPublic = true, paginated = false, sorter='name', direction='ASC') => {
    return appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${catalogContext}/${skillModule}/${profileModule}?isPublic=${isPublic}&paginated=${paginated}&sortBy=${sorter}&sortDir=${direction}`);
}