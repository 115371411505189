import { useContext, useEffect, useState } from 'react';
import styles from '../../candidateInfo.module.scss';
import stylesM from '../../../../components/modal/modal_base.module.scss';
import { AppContext } from '../../context/AppProvider';
import { InputText } from '../../../../components/inputs/InputText';
import Select, { SelectSortType } from '../../../../components/inputs/Select';
import { hardSkillsCatalogId, softSkillsCatalogId, softwareCatalogId, workSkillsCatalogId } from '../../../../core/talent-module/hardcode';
import { selectEmtpy } from '../../../../core/hardcode';
import addIcon from '../../../../../assets/icons/add_circle.svg';
import trashIcon from '../../../../../assets/icons/trash.svg';
import { Checkbox } from '../../../../components/inputs/Checkbox';
import { CandidateContext } from '../../context';
import { validateIsSelected, validateNotNull } from '../../../../core/validators';
import { getSkillTag } from '../../../../api/skillClient';

const validationFields = {
    name: [validateNotNull],
    category: [validateIsSelected]
}

export const validateNewSkill = skill => {
    const errorObject = {};
    var helper;
    for(const field in validationFields) {
        for (const validator in validationFields[field]) {
            helper = validationFields[field][validator](skill[field])
            if (helper) {
                errorObject[field] = helper
            }
        }
    }
    if (skill.traductions.length > 0) {
        skill.traductions.forEach( (s, index) => {
            const descValidation = validateNotNull(s.desc);
            if( descValidation ) {
                errorObject[`transDesc_${index}`] = descValidation;
            }
            const transLangValidation = validateIsSelected(s.lang);
            if( transLangValidation ) {
                errorObject[`transLang_${index}`] = transLangValidation;
            }
        })
    }

    return errorObject;
}

export const SkillNew = ({
    resumeViewFlag,
    renderBackButton,
    catLanguages,
    catType
}) => {

    const appContext = useContext(AppContext);
    const {
        competition,
        setCompetition,
        setCompetitionCache,
        competitionErrors,
        setCompetitionErrors
    } = useContext(CandidateContext);

    const label = competition.categoryId === softwareCatalogId ? 'soft' : ( competition.categoryId === workSkillsCatalogId ? 'work' : 'skills')
    const [optionsSelecteds, setOptionsSelecteds] = useState([]);

    const fetchCompetition = () => {
        if( competition.id ) {
            getSkillTag(competition.id, appContext.userData.token).then( response => {
                if( response.status === 200 && response.data && response.data.code === 302 && response.data.result ) {
                    const transTmp = [];
                    response.data.result.traductions?.map(t => {
                        let langFind = catLanguages.find( c => c.id === t.languageId );
                        langFind = langFind ? { id: langFind.id, description: langFind.optionName } : selectEmtpy;
                        transTmp.push({ desc: t.name, name: t.name, description: t.name, languageId: t.languageId, lang: langFind })
                    })
                    let typeFind = catType.find(t => t.id === response.data.result.categoryId);
                    typeFind = typeFind ? { id: typeFind.id, description: typeFind.optionName } : selectEmtpy;
                    const compTmp = {
                        ...competition,
                        categoryId: response.data.result.categoryId,
                        category: typeFind,
                        isForAllLanguages: response.data.result.isForAllLanguages,
                        isPublic: response.data.result.isPublic,
                        traductions: transTmp
                    }
                    setCompetition(compTmp);
                    setCompetitionCache(compTmp);
                } else {
                    // TODO Manejar respuesta erronea
                }
            }).catch( error => {
                console.log(error);
            })
        } else {
            // Copiar lo de abajo aca arriba
        }
        const type = catType.find( c => c.id === competition.categoryId );
        const comp = {
            ...competition,
            category: type ? { id: type.id, description: type.optionName } : selectEmtpy
        };
        setCompetition(comp)
        setCompetitionCache(comp);
    }

    useEffect(() => {
        if( catLanguages && catLanguages.length > 0 && catType && catType.length > 0 ) {
            fetchCompetition();
        }
    }, [catLanguages, catType]);

    useEffect(() => {
        const menuNagivation = [...appContext.menuNavigation];
        menuNagivation[menuNagivation.length-1].label = getTitle();
        appContext.setMenuNavigation(menuNagivation);
    }, [competition.category])

    const updateCompetition = (property, value) => {
        if( property.includes('transDesc_') ) {
            const index = Number(property.substring(property.indexOf('_')+1, property.length));
            handleOnEdit(true, index, value);
        } else if ( property === 'isForAllLanguages' ) {
            if( !value && competition.traductions.length > 0 ) {
                setCompetition({ ...competition, [property]: value, 'traductions': []});
            } else {
                setCompetition({ ...competition, [property]: value});
            }
        } else {
            setCompetition({ ...competition, [property]: value});
        }
    }

    const updateType = (property, value) => {
         if( property.includes('transLang_') ) {
            const index = Number(property.substring(property.indexOf('_')+1, property.length));
            handleOnEdit(false, index, value);
        } else {
            setCompetition({ ...competition, [property]: value, [`${property}Id`]: value.id, [`${property}Name`]: value.description});
        }
    }

    const handleOnClickAdd = () => {
        const competitionErrors = validateNewSkill(competition);
        setCompetitionErrors(competitionErrors);
        const errors = Object.keys(competitionErrors);
        const errorDesc = errors.filter( w => w.includes('transDesc_')).length > 0;
        const errorLang = errors.filter( w => w.includes('transLang_')).length > 0;
        if( !(errorDesc || errorLang) ) {
            updateCompetition('traductions', [
                ...competition.traductions,
                { desc: '', name: '', description: '', lang: selectEmtpy }
            ])
        }
    }

    const handleOnEdit = (desc, index, value) => {
        const traductions = [  ...competition.traductions ];
        if( desc ) {
            traductions[index].desc = value;
            traductions[index].name = value;
            traductions[index].description = value;
        } else {
            traductions[index].lang = value;
            traductions[index].languageId = value.id;
            // TODO Esto esta mal ya que cuando se modifica un idioma no se libera el anterior
            setOptionsSelecteds([...optionsSelecteds, value.id]);
        }
        updateCompetition('traductions', traductions);
    }

    const handleOnClickRemove = index => {
        setOptionsSelecteds([...optionsSelecteds.slice(0, index), ...optionsSelecteds.slice(index + 1)])
        const traductions = [ ...competition.traductions ];
        traductions.splice(index, 1);
        updateCompetition('traductions', traductions);
        setCompetitionErrors(validateNewSkill({ ...competition, 'traductions': traductions}));
    }

    const renderLanguages = () => competition.traductions.map( (c, index) => (
        <div key={ index } className={ styles.form_columns } style={{ justifyContent: 'unset', marginBottom: '8px' }}>
            <div className={ styles.form_column } style={{ marginRight: '40px' }}>
                <div className={ stylesM.custom_select }>
                    { index === 0 && <label>{ `${appContext.t('candidate.resume.lang.labelLang')}*` }</label> }
                    <Select
                        name={`transLang_${index}`}
                        placeholder={ appContext.t('candidate.resume.chooseOption') }
                        value={ c.lang }
                        onChange={ updateType } 
                        options={ catLanguages }
                        optionsSelecteds={ optionsSelecteds }
                        error= { Object.keys(competitionErrors).includes(`transLang_${index}`) ? competitionErrors[`transLang_${index}`][0] : '' }
                        sort={ SelectSortType.LANG }
                    />
                    {
                        Object.keys(competitionErrors).includes(`transLang_${index}`) && (
                            <p className={ styles.error_message }>{ competitionErrors[`transLang_${index}`][0] }</p>
                        )
                    }
                    { Object.keys(competitionErrors).includes(`transDesc_${index}`) && !Object.keys(competitionErrors).includes(`transLang_${index}`) && '\u00A0' }
                </div>
            </div>
            <InputText 
                cssContainer={ styles.form_column } 
                name={`transDesc_${index}`}
                label={ index === 0  ? `${appContext.t(`candidate.resume.skills.addForm.transLabel`)}*` : undefined } 
                value={ c.desc } 
                onChange={ updateCompetition } 
                error={ Object.keys(competitionErrors).includes(`transDesc_${index}`) ? competitionErrors[`transDesc_${index}`][0] : '' }
                marginError={ Object.keys(competitionErrors).includes(`transLang_${index}`) && !Object.keys(competitionErrors).includes(`transDesc_${index}`) }
            />
            <div className={ styles.flex_column_center } style={{ marginLeft: '8px' }}>
                { index === 0 && '\u00A0' }
                <div className={ styles.action_container }>
                    <img className={ styles.trash } src={ trashIcon } alt="" onClick={ () => handleOnClickRemove(index) } />
                </div>
            </div>
        </div>
    ))

    const renderMainSection = () => (
        <div className={ styles.form_wrapper }>
            <div className={ styles.form_columns } style={{ justifyContent: 'unset' }}>
                <InputText
                    cssContainer={ styles.form_column }
                    name="name"
                    label={ `${appContext.t(`candidate.resume.${ label }.addForm.labelName`)}*` }
                    value={ competition.name }
                    onChange={ updateCompetition }
                    error={ Object.keys(competitionErrors).includes("name") ? competitionErrors.name[0] : '' }
                    marginError={ Object.keys(competitionErrors).includes("category") && !Object.keys(competitionErrors).includes("name") }
                />
                { (!appContext.talent || competition.id) ?
                    <div className={ styles.form_column } style={{ marginLeft: '40px' }}>
                        <div className={ stylesM.custom_select }>
                            <label>{ `${appContext.t('candidate.resume.skills.addForm.labelType')}*` }</label>
                            <Select
                                name="category"
                                placeholder={ appContext.t('candidate.resume.chooseOption') }
                                value={ competition.category }
                                onChange={ updateType } 
                                options={ catType }
                                error= { Object.keys(competitionErrors).includes("category") ? competitionErrors.category[0] : '' }
                                sort={ SelectSortType.ALPHA }
                            />
                            {
                                Object.keys(competitionErrors).includes("category") && (
                                    <p className={ styles.error_message }>{ competitionErrors.category[0] }</p>
                                )
                            }
                            { Object.keys(competitionErrors).includes("name") && !Object.keys(competitionErrors).includes("category") && '\u00A0' }
                        </div>
                    </div> : ''
                }
                { competition.category.id !== softwareCatalogId &&
                    <div className={ styles.flex_column_center }>
                        &nbsp;
                        &nbsp;
                    </div>
                }
            </div>
        </div>
    )

    const renderLanguageSection = () => (
        <div className={ styles.form_column_simple }>
            <label className={ styles.subtitle }>{ appContext.t('translate.subtitle') }</label>
            <p>{ appContext.t(`candidate.resume.${ label }.addForm.transInst`) }</p>
            { !competition.isForAllLanguages &&
                <div>
                    { renderLanguages() }
                    <div className={ styles.form_row} >
                        <button className={ styles.add_info } onClick={() => handleOnClickAdd()}>
                            { appContext.t('translate.labelAdd') }
                            <img src={ addIcon } alt="" />
                        </button>
                    </div>
                </div>
            }
            { (competition.traductions.length === 0 || competition.isForAllLanguages) &&
                <Checkbox
                    name="isForAllLanguages"
                    label = { appContext.t(`candidate.resume.${ label }.addForm.sameNameTrans`) }
                    value={ competition.isForAllLanguages }
                    onClick={ updateCompetition } />
            }
        </div>
    )

    const renderSuggestionSection = () => (
        <div className={ styles.form_column_simple }>
            <label className={ styles.subtitle }>{ appContext.t('candidate.resume.skills.addForm.labelSuggestion') }</label>
            <p>{ appContext.t('candidate.resume.skills.addForm.infoSuggestion') }</p>
            <Checkbox
                name="isPublic"
                label = { appContext.t('candidate.resume.skills.addForm.showSuggestion') }
                value={ competition.isPublic }
                onClick={ updateCompetition } />
        </div>
    )

    const getTitle = () => {
        const title1 = appContext.t(`candidate.resume.skills.addForm.title${ competition.id ? 'Edit' : 'Create' }`);
        const title2 = !competition.categoryId ? appContext.t('candidate.resume.skills.addForm.titleSkill') :
        (
            competition.categoryId === softwareCatalogId ? appContext.t('candidate.resume.soft.title') :
            (
                competition.categoryId === hardSkillsCatalogId ? appContext.t('candidate.resume.skills.addForm.titleHard') :
                (
                    competition.categoryId === softSkillsCatalogId ? appContext.t('candidate.resume.skills.addForm.titleSoft') :
                    (
                        competition.categoryId === workSkillsCatalogId ? appContext.t('candidate.resume.work.addForm.titleWork') :
                        catType.find( c => c.id === competition.categoryId).optionName
                    )
                )
            )
        )
        return `${title1} ${title2}`;
    };

    const renderTitle = () => <p className={ styles.title }>{ getTitle() }</p>;

    return (
        <div className={ styles.form_container }>
            { appContext.isDesktopOrLaptop ? renderTitle() : ( resumeViewFlag ? renderTitle() : renderBackButton(appContext.t(`candidate.resume.${ label }.addForm.title`))) }
            { renderMainSection() }
            { renderLanguageSection() }
            { renderSuggestionSection() }
        </div>
    )
}
