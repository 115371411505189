import styles from '../../checkup.module.scss';
import stylesC from '../../../candidates/candidateInfo.module.scss';
import { TextArea } from '../../../../components/inputs/TextArea';

export const ClimateFollowup = ({
    appContext,
    checkup,
    checkupErrors,
    onChangeInput
}) => {

    const onChangeComment = ( id, value ) => {
        const commentsTmp = [ ...checkup.followUps ];
        const index = commentsTmp.findIndex( c => c.id === id );
        commentsTmp[index].value = {
            ...commentsTmp[index].value,
            comment: value
        }
        onChangeInput('followUps', commentsTmp);
    }

    const renderFolloups = () => checkup.followUps.map((f, index) => (
        <div key={ index } className={styles.section}>
            <div  className={ stylesC.section_header}>
                <div className={ stylesC.section_title }>
                    <div className={ stylesC.decorator }></div>
                    { f.title }
                </div>
            </div>
            <div className={styles.section}>
                <div className={ styles.comment }>
                    <TextArea
                        cssCounter={ styles.subtitle_left }
                        name={ f.id }
                        label={ `${f.question.replace(':name', appContext.talent.firstName)}*` }
                        maxLength={ 3000 }
                        errorLen={ 'validator.length' }
                        value={ f.value?.comment || "" }
                        onChange={ onChangeComment }
                        error={ Object.keys(checkupErrors).includes(`follow_${index}`) ? checkupErrors[`follow_${index}`][0] : '' }
                        focus={ Object.keys(checkupErrors).includes(`follow_${index}_focus`) }
                    />
                </div>
            </div>
        </div>
    ))

    return checkup.followUps && renderFolloups();
}
