import { useState } from "react";
import stylesHeader from '../../../components/header/header.module.scss';
import stylesModal from '../../../components/modal/modal_base.module.scss';
import { Checkbox } from '../../../components/inputs/Checkbox';

export const ModalSendLeader = ({
    appContext,
    review,
    setReview,
    reviews,
    setReviews,
    updateStatus
}) => {

    const [noShowAgain, setNoShowAgain] = useState(false);

    const onClickUpdateStatus = () => {
        updateStatus({
            "appContext": appContext,
            "review": review,
            "setReview": setReview,
            "reviews": reviews,
            "setReviews": setReviews,
            statusId: 1000530003,
            message: 'checkUp.report.sendMessage'
        });
        appContext.setModalFlag(false);
    }

    const onClickNoShowAgain = (name, checkValue) => {
        setNoShowAgain(checkValue)
        if( checkValue ) {
            localStorage.setItem('noShowCheckUpModalSendLeader', new Date());
        } else {
            localStorage.removeItem('noShowCheckUpModalSendLeader');
        }
    }

    return (
        <div className={ stylesHeader.error_modal }>
            <div className={ stylesHeader.wrapper }>
                <div className={ stylesHeader.content_title }>
                    <p className={ stylesHeader.title }>{ appContext.t('checkUp.report.modalSendLeader.title') }</p>
                </div>
                <span className={ stylesHeader.bold }>{ appContext.t('checkUp.report.modalSendLeader.label').replace(':name', appContext.talent.currentLeader) }</span>
                <ul style={{margin: '0'}}>
                    <li>{ appContext.t('checkUp.report.modalSendLeader.msg1') }</li>
                    <li>{ appContext.t('checkUp.report.modalSendLeader.msg2').replace(':name', appContext.talent.firstName) }</li>
                    <li>{ appContext.t('checkUp.report.modalSendLeader.msg3').replace(':name', appContext.talent.firstName) }</li>
                </ul>
                <Checkbox
                    cssLabel={ stylesModal.subtitle }
                    name="noShowAgain"
                    label={ appContext.t('checkUp.report.modalSendLeader.noShowAgain') }
                    value={ noShowAgain }
                    onClick={ onClickNoShowAgain }
                />
                <div className={ stylesHeader.content_buttons }>
                    <div className={ stylesHeader.buttons }>
                        <button className={ stylesHeader.cancel } onClick={ () => appContext.setModalFlag(false) }>{ appContext.t('button.cancel') }</button>
                        <button className={ stylesHeader.confirm } onClick={ onClickUpdateStatus }>{ appContext.t('checkUp.report.modalSendLeader.sendBtnLabel') }</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
