import { appApi, createJsonAccessToken } from "./apiInstance";

const apiUrl = process.env.REACT_APP_GATEWAY_URL;

const mainContext = 'talent';
const module = 'education';

export const newEducation = (talentId, workExperience, userId, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).post(`${apiUrl}${mainContext}/${talentId}/${module}/new?userId=${userId}`, workExperience);

export const editEducation = (talentId, workExperience, userId, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).put(`${apiUrl}${mainContext}/${talentId}/${module}/edit?userId=${userId}`, workExperience);

export const getEducations = (talentId, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${apiUrl}${mainContext}/${talentId}/${module}`);

export const deleteEducation = (talentId, id, userId, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).delete(`${apiUrl}${mainContext}/${talentId}/${module}/${id}/delete?userId=${userId}`);