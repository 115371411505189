import stylesC from '../../checkup.module.scss';
import styles from '../../../candidates/candidateInfo.module.scss';
import editIcon from '../../../../../assets/icons/orange_pencil.svg';

export const FeedbackCommentsResume = ({
    appContext,
    changeTap,
    review,
    checkup
}) => {
    
    const renderSkillsSection = () => {
        const skillSections = []
        if( review.strengths && review.strengths.length > 0 ) {
            skillSections.push({
                title: appContext.t('candidate.resume.review.strength'),
                skills: review.strengths,
                comment: review.strengthsComment,
                labelNoSkills: appContext.t('candidate.resume.review.notStrength'),
                typeId: 1
            })
        }
        if( review.opportunities && review.opportunities.length > 0 ) {
            skillSections.push({
                title: appContext.t('candidate.resume.review.opportunities'),
                skills: review.opportunities,
                comment: review.opportunitiesComment,
                labelNoSkills: appContext.t('candidate.resume.review.notOpportunities'),
                typeId: 2
            })
        }
        return skillSections.map((s, index) => (
            <div key={index} className={ `${stylesC.section} ${stylesC.gap16}` }>
                <span className={ stylesC.label }>{ appContext.t('candidate.resume.review.commentOn') } { s.title }</span>
                <span>{ checkup.feedbackComments.find( c => c.typeId === s.typeId ).comments }</span>
            </div>
        ))
    }

    const renderMainRow = () => (
        <div className={ styles.section_header}>
            <div className={ styles.section_title }>
                <div className={ styles.decorator }></div>
                { appContext.t('checkUp.report.commentsLabel').replace(':name', appContext.talent.firstName) }
            </div>
            {
                <div className={ styles.button_container }>
                    <div className={ styles.vertical_separator }></div>
                    <button className={ styles.add_info } onClick={() => changeTap(3)}>
                        { appContext.t('candidate.resume.edit') }
                        <img src={ editIcon } alt="" />
                    </button>
                </div>
            }
        </div>
    )

    return checkup.feedbackComments &&  (
        <div className={ `${styles.form_container} ${stylesC.checkup}` }>
            { renderMainRow() }
            { renderSkillsSection() }
        </div>
    )
}
