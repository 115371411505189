import styles from '../../checkup.module.scss';
import { BarEvaluation } from '../../../../components/inputs/BarEvaluation';

export const ClimateAbout = ({
    appContext,
    comments,
    catLevelSatisfaction
}) => {
    return (
        <div className={ styles.table }>
            <div className={ `${styles.row} ${styles.header}` }>
                <div className={ styles.column_main }>{ appContext.t('checkUp.climate.detailAbout.levelLabel') }</div>
                <div className={ styles.column_detail }>{ appContext.t('checkUp.climate.commentLabel') }</div>
            </div>
            <div className={ `${styles.detail} ${styles.gap8}` }>
                {
                    comments && comments.map((c, index) => (
                        <div key={index} className={ `${styles.row} ${styles.row_backgray}` }>
                            <div className={ `${styles.column_main} ${styles.bar} ${styles.gap16}` }>
                                <span className={ styles.label }>{ c.title }</span>
                                <BarEvaluation
                                    classMain={ `${styles.bar} ${styles.gap4}` }
                                    value={ c.value }
                                    options={ catLevelSatisfaction }
                                    modeRead
                                    width={ 70 }
                                />
                            </div>
                            <div className={ `${styles.column_detail} ${styles.comment}` }>{ c.value.comment }</div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
