import { useContext } from 'react'
import styles from '../../components/modal/modal_base.module.scss'
import { AppContext } from '../candidates/context/AppProvider';

export const VerificationModal = ({
    onClickVerificyCode
}) => {

    const appContext = useContext(AppContext);

    return (
        <div className={ styles.modal_verification_code  }>
            <p className={ styles.title }>{appContext.t('login.modalVerification.title')}</p>
            <p className={ styles.subtitle }>{appContext.t('login.modalVerification.subtitle')}</p>
            <div className={ styles.buttons }>
                <button className={ `${styles.cancel_button} ${styles.mobile}` } onClick={() => appContext.setModalFlag(false)}>{appContext.t('button.back')}</button>
                <button onClick={ onClickVerificyCode }>{appContext.t('login.modalVerification.btnMain')}</button>
            </div>
        </div>
    )

}
