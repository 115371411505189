import {
    saveCheckUp,
    saveCheckUpFollowItem,
    saveCheckUpItem,
    saveComments,
    saveImprovements,
    saveInternalNotes,
    sendCheckupCompletion,
    shareWithLeader,
    updateFeedbackStatus
} from "../../../api/feedbackClient";
import { saveWorkEnv, updateWorkEnv } from "../../../api/talentInfoClient";
import { loadFollowUp } from "./loadThunks";

const persistComments = (context, checkupId) => {
    if( context.checkup.comments ) {
        context.checkup.comments.forEach(c => {
            c.satisfactionLevelId = c.value.id;
            c.comments = c.value.comment;
            c.talentCheckUpItemTypeId = c.id;
            saveCheckUpItem(context.appContext.userData.userProfile.id, context.appContext.talent.id, checkupId, c, context.appContext.userData.token).then(response => {
                // Comment saved
            }).catch(error => {
                console.log(error);
            })
        })
    }
    if( context.checkup.followUps ) {
        context.checkup.followUps.forEach(c => {
            c.satisfactionLevelId = c.value.id;
            c.comments = c.value.comment;
            c.talentCheckUpFollowItemTypeId = c.id;
            saveCheckUpFollowItem(context.appContext.userData.userProfile.id, context.appContext.talent.id, checkupId, c, context.appContext.userData.token).then(response => {
                // Follow saved
            }).catch(error => {
                console.log(error);
            })
        })
    }
}

const persistWorkEnv = context => {
    const request = {
        classificationId: context.checkup.clasification.id,
        exitRisk: context.checkup.existRisk.id,
        createdBy: context.appContext.userData.userProfile.id,
        updatedBy: context.appContext.userData.userProfile.id
    }
    if( context.checkup.workEnviroimentId ) {
        updateWorkEnv(context.appContext.talent.id, request, context.appContext.userData.token).then(response => {
            // Work enviroiment updated
        }).catch(error => {
            console.log(error);
        })
    } else {
        saveWorkEnv(context.appContext.talent.id, request, context.appContext.userData.token).then(response => {
            // Work enviroiment saved
        }).catch(error => {
            console.log(error);
        })
    }
}

export const onSaveClimate = context => {
    saveCheckUp(context.appContext.userData.userProfile.id, context.appContext.talent.id, context.checkup, context.appContext.userData.token).then(response => {
        persistWorkEnv(context);
        if( response.status === 200 && response.data.code === 202 ) {
            if (!context.checkup.id && context.tap === 1) {
                const checkUpNew = {
                    ...context.checkup,
                    id: response.data.result.id,
                    createdBy: response.data.result.createdBy,
                    processStatus: response.data.result.processStatus
                };
                persistComments(context, response.data.result.id);
                loadFollowUp(checkUpNew, context);
            } else {
                persistComments(context, context.checkup.id);
                if ( context.tap === 1 ) {
                    context.changeTap(0);
                } else if (context.tap === 0) {
                    context.changeTap(context.modeUdpate ? 6 : 3);
                }
            }
        }
        context.appContext.contentScrollUp();
        context.appContext.displayNotification((context.appContext.t('update')));
    }).catch(error => {
        console.log('Error to save climate', error);
    })
}

export const isGoToImproveSection = (review, changeTap, forward) => {
    const isThereNoOpportunities = !review.opportunities || review.opportunities.length === 0;
    const tapForward = isThereNoOpportunities ? 5 : 4;
    const tapBackward = isThereNoOpportunities ? 3 : 4;
    changeTap(forward ? tapForward : tapBackward);
}

export const onSaveComments = context => {
    if( context.checkup.feedbackComments.length > 0 ) {
        const requestArray = []
        context.checkup.feedbackComments.forEach(c => requestArray.push(saveComments(context.appContext.userData.userProfile.id, context.appContext.talent.id, context.review.id, c, context.appContext.userData.token)))
        Promise.all(requestArray).then( responses => {
            responses.forEach((response, index) => {
                if(index === requestArray.length - 1) {
                    context.appContext.displayNotification((context.appContext.t('update')));
                    isGoToImproveSection(context.review, context.changeTap, true);
                }
            })
        }).catch( error => {
            console.log(error);
        });
    } else {
        isGoToImproveSection(context.review, context.changeTap, true);
    }
}

export const onSaveImprovementActions = context => {
    const requestArray = [];
    context.checkup.improvements.forEach(c => requestArray.push(saveImprovements(context.appContext.userData.userProfile.id, context.appContext.talent.id, context.review.id, c, context.appContext.userData.token)))
    Promise.all(requestArray).then( responses => {
        responses.forEach((response, index) => {
            if(index === requestArray.length - 1) {
                context.appContext.displayNotification((context.appContext.t('update')));
                context.changeTap(context.tap+1);
            }
        })
    }).catch( error => {
        console.log(error);
    });
}

export const onSaveInternalNotes = context => {
    const requestArray = [];
    context.checkup.internalNotes.forEach(i => {
        if( (i.isNew && i.comments.length > 0) || !i.isNew ) {
            requestArray.push(saveInternalNotes(context.appContext.userData.userProfile.id, context.appContext.talent.id, context.checkup.id, i, context.appContext.userData.token))
        }
    })
    const updateStatus = context.review.processStatus.id === 1000530001;
    if( updateStatus ) {
        requestArray.push(sendCheckupCompletion(context.appContext.userData.userProfile.id, context.appContext.talent.id, context.checkup.id, context.review.id, context.appContext.userData.token))
        const followDoneStatus = 1000530002;
        requestArray.push(updateFeedbackStatus(context.appContext.userData.userProfile.id, context.appContext.talent.id, context.review.id, followDoneStatus, context.appContext.userData.token));
    }
    Promise.all(requestArray).then(responses => {
        responses.forEach((response, index) => {
            if(index === requestArray.length - 1) {
                context.appContext.displayNotification(context.appContext.t('checkUp.msgFinish'));
                context.changeTap(context.tap+1);
                if( updateStatus ) {
                    handleUpdateStatus(context, response);
                }
            }
        })
    }).catch( error => {
        console.log(error);
    });
}

export const onUpdateStatus = context => {
    shareWithLeader(context.appContext.userData.userProfile.id, context.appContext.talent.id, context.checkup.id, context.review.id, context.appContext.userData.token).then(response => {
        if( response.status === 200 ) {
            updateFeedbackStatus(context.appContext.userData.userProfile.id, context.appContext.talent.id, context.review.id, context.statusId, context.appContext.userData.token).then(response => {
                handleUpdateStatus(context, response);
                if( response.status === 200 && response.data.code === 202 ) {
                    context.appContext.displayNotification(context.appContext.t(context.message));
                }
            }).catch(error => {
                console.log(error)
            })
        }
    }).catch(error => {
        console.log(error);
    })
}

const handleUpdateStatus = (context, response) => {
    const { status, data } = response;
    if( status === 200 && data.code === 202 ) {
        // Update status in review
        context.setReview({ ...context.review, 'processStatus': data.result.processStatus });
        // Update status in review list
        const reviewsTmp = [ ...context.reviews ];
        const reviewIndex = reviewsTmp.findIndex( r => r.id === context.review.id );
        reviewsTmp[reviewIndex].processStatus = data.result.processStatus;
        context.setReviews(reviewsTmp);
    }
}