import i18next from "../../../i18n";
import moment from "moment/moment";
import { encryptString } from "./crypto";

export const defaultStringEmpty = value => {
    return value && value.trim() !== '' ? value : i18next.t('noData.unspec');
}

export const sorterAlphabetic = (a, b) => {
    const textA = a.toUpperCase();
    const textB = b.toUpperCase();
    const result = (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    return result;
}

export const borderError = error => error ? { borderColor: 'var(--color-error)' } : {}

export const stringCutter = (value, maxLength) => value.length > maxLength ? `${value.substring(0, maxLength)}...` : value

export const getFilterDate = () => {
    const formatPatter = 'YYYY-MM-DD';
    return [
        {
            id: 1, optionName: i18next.t('filter.date.now'),
            range: { start: moment().format(formatPatter), end: moment().add(1, 'days').format(formatPatter) }
        },
        {
            id: 2, optionName: i18next.t('filter.date.sevenDaysAgo'),
            range: { start: moment().subtract(7, 'days').format(formatPatter), end: moment().add(1, 'days').format(formatPatter) }
        },
        {
            id: 3, optionName: i18next.t('filter.date.fifteenDaysAgo'),
            range: { start: moment().subtract(15, 'days').format(formatPatter), end: moment().add(1, 'days').format(formatPatter) }
        },
        {
            id: 4, optionName: i18next.t('filter.date.oneMonthAgo'),
            range: { start: moment().subtract(1, 'months').format(formatPatter), end: moment().add(1, 'days').format(formatPatter) }
        },
        {
            id: 5, optionName: i18next.t('filter.date.threeMonthAgo'),
            range: { start: moment().subtract(3, 'months').format(formatPatter), end: moment().add(1, 'days').format(formatPatter) }
        },
        {
            id: 6, optionName: i18next.t('filter.date.sixMonthAgo'),
            range: { start: moment().subtract(6, 'months').format(formatPatter), end: moment().add(1, 'days').format(formatPatter) }
        }
    ]
}

export const getSharedLink = data => {
    const token = encryptString(JSON.stringify({ ...data, expirationDate: moment().add(1, 'hours') }));
    return `${process.env.REACT_APP_URL}shared-link?token=${token}`
}

export const checkSharedLinkExpiration = expirationDate => {
    return moment().diff(expirationDate, 'minutes');
}