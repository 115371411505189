import { useContext, useEffect, useState } from 'react';
import styles from '../feedback.module.scss';
import { AppContext } from '../../candidates/context/AppProvider';
import shareIcon from '../../../../assets/icons/share.svg';
import addIcon from '../../../../assets/icons/add_circle.svg';
import { Checkup } from '../../checkup/Checkup';
import { havePermission } from '../../../api/userClient';
import { FeedbackArrows } from './FeedbackArrows';
import { FeedbackStarts } from './FeedbackStarts';
import { FeedbackSkillPills } from './FeedbackSkillPills';
import { getCheckUpByTalent } from '../../../api/feedbackClient';
import { getStatusDescription } from '../FeedbackList';
import { getSharedLink } from '../../../core/talent-module/helper';

export const FeedbackDetail = ({
    catType,
    review,
    setReview,
    reviews,
    setReviews,
    catFeedbackStatus,
    showReport
}) => {

    const appContext = useContext(AppContext);
    const [child, setChild] = useState()
    const [showButtonCheckup, setShowButtonCheckup] = useState(false);
    const [checkup, setCheckup] = useState();

    useEffect(() => {
        havePermission(appContext.userData.token, appContext.userData.userProfile.id, [1000000011]).then(({status, data}) => {
            if(status === 200 && data.code === 202 && data.result) {
                const showButtonCheckup = data.result;
                setShowButtonCheckup(showButtonCheckup);
                getCheckUpByTalent(appContext.userData.userProfile.id, appContext.talent.id, appContext.userData.token).then(({status, data}) => {
                    const {result} = data;
                    if( status === 200 && data.code === 202 && Array.isArray(result) && result.length > 0) {
                        setCheckup(result[0].checkUp);
                        if( showButtonCheckup && showReport ) {
                            onClickCheckUp();
                        }
                    }
                }).catch(error => {
                    console.log(error);
                })
            }
        }).catch(error => {
            console.log(error)
        })
    }, [])

    const onClickShare = () => {
        navigator.clipboard.writeText(getSharedLink({
            userId: appContext.userData.userProfile.id,
            talentId: appContext.talent.id,
            reviewId: review.id
        }));
        appContext.displayNotification(appContext.t('catalogs.user.linkCopied'));
    }

    const onClickCheckUp = () => {
        let menuNavigation = [ ...appContext.menuNavigation ];
        menuNavigation.pop();
        menuNavigation.push({
            label: `${appContext.t('candidate.resume.review.title')} ${review.period.substring(0, review.period.lastIndexOf(' '))}`,
            onEvent: setChild,
            arg: null
        });
        menuNavigation.push({ label: appContext.t('checkUp.climate.title') });
        appContext.setMenuNavigation(menuNavigation);
        appContext.contentScrollUp();
        setChild(
            <Checkup
                appContext={appContext}
                review={review}
                setReview={setReview}
                reviews={reviews}
                setReviews={setReviews}
                setChild={setChild}
                catType={catType}
            />
        )
    }

    const getReviewLabel = () => {
        if (review) {
            if (review.processStatus.id === 1000530001 && checkup && checkup.id) {
                return 'checkUp.continueLabel';
            } else if (review.processStatus.id >= 1000530002) {
                return 'checkUp.viewLabel';
            }
        }
        return 'candidate.resume.review.checkUpAdd';
    }

    const renderButtons = () => showButtonCheckup && (
        <div className={ styles.section_main }>
            <div className={ `${styles.section_main} ${styles.button} ${styles.button_share}` } onClick={ onClickShare }>
                {appContext.t('candidate.resume.review.shareLink')}
                <img src={ shareIcon } width={ 24 } height={ 24 } alt="" />
            </div>
            <div className={ `${styles.section_main} ${styles.button} ${styles.button_checkup}` } onClick={ onClickCheckUp }>
                {appContext.t(getReviewLabel())}
                <img src={ addIcon } width={ 24 } height={ 24 }  alt="" />
            </div>
        </div>
    )

    const renderDetailSection = (title, child) => (
        <div className={ styles.section }>
            <div className={ styles.title }><h2 className={ styles.p_title }>{ title }</h2></div>
            <div className={ styles.content }>
                { child }
            </div>
        </div>
    )

    const renderSkillsSection = () => {
        const skillSections = [
            {
                title: appContext.t('candidate.resume.review.strength'),
                skills: review.strengths,
                comment: review.strengthsComment,
                labelNoSkills: appContext.t('candidate.resume.review.notStrength')
            },
            {
                title: appContext.t('candidate.resume.review.opportunities'),
                skills: review.opportunities,
                comment: review.opportunitiesComment,
                labelNoSkills: appContext.t('candidate.resume.review.notOpportunities')
            }
        ]
        return <FeedbackSkillPills appContext={appContext} skillSections={skillSections} catType={catType} />
    }

    const renderMail = () => (
        <div className={ styles.feedback }>
            <div className={ styles.section_review }>
                <div className={ styles.section_main }>
                    <span className={ styles.title_24px }>{appContext.t('candidate.resume.review.title')} {review.period}</span>
                    { renderButtons() }
                </div>
                <div className={ styles.title }>
                    <span className={ styles.p_title }>{appContext.t('candidate.list.headers.status')}: </span>
                    { review.processStatus?.name || getStatusDescription(review.processStatus.id, catFeedbackStatus) }
                    <div className={ styles.stars }>{ <FeedbackArrows status={review.processStatus?.id} catStatus={catFeedbackStatus} /> }</div>
                </div>
                <div className={ styles.title }>
                    <span className={ styles.p_title }>{appContext.t('candidate.resume.review.reviewer')}:</span> {review.authorName}
                </div>
                { renderDetailSection( `${appContext.t('candidate.list.headers.qualification')}:`, <FeedbackStarts rate={review.rate} />) }
            </div>
            { renderSkillsSection() }
        </div>
    )

    return child ? child : renderMail();
}
