import styles from '../../checkup.module.scss';
import stylesC from '../../../candidates/candidateInfo.module.scss';
import { TextArea } from '../../../../components/inputs/TextArea';
import editIcon from '../../../../../assets/icons/orange_pencil.svg';
import { validateNotNull } from '../../../../core/validators';

export const validateInternalNotes = checkup => {
    const noteToken = 'note';
    const checkupErrors = {}
    let firstError = false;
    checkup.internalNotes.forEach( c => {
        const commentValidation = validateNotNull(c.comments);
        if( !c.isNew && commentValidation ) {
            checkupErrors[`${noteToken}_${c.noteTypeId}`] = commentValidation;
            if(!firstError) {
                checkupErrors[`${noteToken}_${c.noteTypeId}_focus`] = true;
                firstError = true;
            }
        }
    });
    return checkupErrors;
}

export const InternalNotes = ({
    appContext,
    checkup,
    checkupErrors,
    onChangeInput,
    modeResume,
    changeTap,
    modeRead,
    setModeRead
}) => {

    const onChangeComment = ( id, value ) => {
        const commentsTmp = [ ...checkup.internalNotes ];
        const index = commentsTmp.findIndex( c => c.noteTypeId === id );
        commentsTmp[index] = {
            ...commentsTmp[index],
            comments: value
        }
        onChangeInput('internalNotes', commentsTmp);
    }

    const onClickEdit = () => {
        changeTap(5);
        setModeRead(false);
    }

    const renderComments = () => checkup.internalNotes.map((i, index) => (
        <div key={index} className={ styles.comment }>
            <TextArea
                cssCounter={ styles.subtitle_left }
                name={ i.noteTypeId }
                label={''}
                sublabel={ i.noteTypeTitle }
                cssSubtitle={ styles.sublabel }
                maxLength={ 3000 }
                errorLen={ 'validator.length' }
                value={ i.comments }
                onChange={ onChangeComment }
                error={ Object.keys(checkupErrors).includes(`note_${i.noteTypeId}`) ? checkupErrors[`note_${i.noteTypeId}`][0] : '' }
                focus={ Object.keys(checkupErrors).includes(`note_${i.noteTypeId}_focus`) }
            />
        </div>
    ))

    const getNoCommentLabel = noteTypeId => {
        const label = noteTypeId === 1000590001 ? 'FinalComment' : ( noteTypeId === 1000590002 ? 'NextSteps' : null )
        return label ? appContext.t(`checkUp.report.no${label}`) : '';
    }

    const renderCommentsResume = () => checkup.internalNotes.map((i, index) => (
        <div key={index} className={ `${styles.section} ${styles.gap16}` }>
            <span className={ styles.label }>{ i.noteTypeTitle }</span>
            <span>{ i.comments ? i.comments : getNoCommentLabel(i.noteTypeId) }</span>
        </div>
    ))

    const renderMainRow = () => (
        <div className={ stylesC.section_header}>
            <div className={ stylesC.section_title }>
                <div className={ stylesC.decorator }></div>
                { appContext.t('checkUp.notes.title') }
            </div>
            {
                modeRead &&
                <div className={ stylesC.button_container }>
                    <div className={ stylesC.vertical_separator }></div>
                    <button className={ stylesC.add_info } onClick={onClickEdit}>
                        { appContext.t('candidate.resume.edit') }
                        <img src={ editIcon } alt="" />
                    </button>
                </div>
            }
        </div>
    )

    return (
        <div className={ `${stylesC.form_container} ${styles.checkup}` }>
            { !modeResume && <p className={ stylesC.title }>{ appContext.t('candidate.resume.review.checkUpAdd') }</p> }
            { renderMainRow() }
            <div>
                { appContext.t('checkUp.notes.subtitle1') } <b>{ appContext.t('checkUp.notes.subtitle2') }</b> { appContext.t('paginator.of') } {appContext.talent.firstName}
            </div>
            <div className={ `${styles.section} ${modeResume ? styles.gap16 : styles.gap8}` }>
                { checkup.internalNotes && ( modeRead ? renderCommentsResume() : renderComments()) }
            </div>
        </div>
    )
}
