import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { decryptString } from "../../core/talent-module/crypto";
import { checkSharedLinkExpiration } from "../../core/talent-module/helper";

export const SharedLink = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        let token = searchParams.get("token");
        if( token ) {
            token = token.replaceAll(' ', '+').replaceAll(/["]/g, '');
            const tokenObject = JSON.parse(decryptString(token));
            //const checkExpiration = checkSharedLinkExpiration(tokenObject.expirationDate);
            //if( checkExpiration <= 0 ) {
                if( tokenObject.reviewId ) {
                    localStorage.setItem('feedbackData', JSON.stringify(tokenObject));
                }
            //}
        }
        navigate('/home');
    }, [])
}
