import {
    getCheckUpByTalent,
    getCheckUpFollowUp,
    getCheckUpQuestions,
    getComments,
    getImprovements,
    getInternalNotes
} from "../../../api/feedbackClient";
import { getWorkEnv } from "../../../api/talentInfoClient";
import { getUser } from "../../../api/userClient";

const loadCreator = (checkup, context) => {
    getUser(context.appContext.userData.token, checkup.createdBy).then(response => {
        if( response.status === 200 && response.data.code === 202 && response.data.result ) {
            const { firstName, lastName } = response.data.result;
            const newCheckup = {
                ...checkup,
                creator: `${firstName} ${lastName}`
            }
            context.setCheckup(newCheckup);
            context.setCheckupCache(JSON.stringify(newCheckup));
        }
    }).catch(error => {
        console.log(error)
        context.setCheckup(checkup);
        context.setCheckupCache(JSON.stringify(checkup));
    })
}

const getCommentsInit = (comments, typeId, items = []) => {
    comments.forEach( c => {
        const itemFinded = items.find( i => i[typeId] === c.id )
        if( !c.value ) {
            c.value = itemFinded ? {
                id: itemFinded.satisfactionLevelId,
                comment: itemFinded.comments
            } : { id: 1000570003, comment: '' }
        }
    })
    return comments;
}

export const loadFollowUp = (checkup, context, items) => {
    getCheckUpFollowUp(context.appContext.userData.token).then( response => {
        if( response.status === 200 && response.data && response.data.code === 202 && response.data.result && Array.isArray(response.data.result) ) {
            checkup.followUps = getCommentsInit(response.data.result.filter(c => c.isActive), 'talentCheckUpFollowItemTypeId', items);
            loadCreator(checkup, context);
        }
    }).catch( error => {
        console.log(error);
        context.setCheckup(checkup);
        context.setCheckupCache(JSON.stringify(checkup));
    });
}

const getNotesInit = (internalNotes, catInternalNotes) => {
    const notes = []
    catInternalNotes.forEach(c => {
        const internalNote = internalNotes ? internalNotes.find(i => i.noteTypeId === c.id) : null
        notes.push({
            noteTypeId: internalNote ? internalNote.noteTypeId : c.id,
            noteTypeTitle: c.optionName,
            comments: internalNote ? internalNote.comments : '',
            isNew: internalNote ? false : true
        })
    })
    return notes;
}

const loadInternalNotes = (checkup, comments, catInternalNotes, context) => {
    getInternalNotes(context.appContext.userData.userProfile.id, context.appContext.talent.id, checkup.id, context.appContext.userData.token).then(response => {
        if( response.status === 200 && response.data.code === 202 && Array.isArray(response.data.result) && response.data.result.length > 0 ) {
            checkup.internalNotes = getNotesInit(response.data.result, catInternalNotes);
        } else {
            checkup.internalNotes = getNotesInit(null, catInternalNotes);
        }
        loadFollowUp(checkup, context, comments);
    }).catch(error => {
        console.log(error);
        checkup.internalNotes = getNotesInit(null, catInternalNotes);
        context.setCheckup(checkup);
        context.setCheckupCache(JSON.stringify(checkup));
    })
}

const loadCheckup = (checkup, comments, context, catalogs) => {
    getCheckUpByTalent(context.appContext.userData.userProfile.id, context.appContext.talent.id, context.appContext.userData.token).then(response => {
        context.appContext.showLoading(false, '', context.style);
        const results = response.data.result;
        if( response.status === 200 && response.data.code === 202 && Array.isArray(results) && results.length > 0) {
            const result = results[0]
            const newCheckup = {
                ...checkup,
                ...result.checkUp,
                comments: getCommentsInit(comments.filter(c => c.isActive), 'talentCheckUpItemTypeId', result.items)
            }
            loadInternalNotes(newCheckup, result.followItems, catalogs[3], context);
        } else if( response.status === 200 && response.data.code === 202 && Array.isArray(results) && results.length === 0) {
            const checkUpNew = {
                ...checkup,
                comments: getCommentsInit(comments.filter(c => c.isActive), 'talentCheckUpItemTypeId'),
                clasification: { id: 1000580001 },
                existRisk: { id: 1000560001 }
            }
            context.setCheckup(checkUpNew);
            context.setCheckupCache(JSON.stringify(checkUpNew));
            context.changeTap(1)
        }
    }).catch(error => {
        context.appContext.showLoading(false, '', context.style);
        console.log(error);
    })
}

const buildImprovement = (skill, comment, context) => {
    return {
        feedbackId: comment ? comment.feedbackId : context.review.id,
        skillTagId: skill.tagId,
        comments: comment ? comment.comments: '',
        createdBy: comment ? comment.createdBy : context.appContext.userData.userProfile.id,
        name: skill.name,
        categoryId: skill.categoryId
    }
}

const getImprovementInit = (comments, context) =>  {
    const improvements = [];
    context.review.opportunities.forEach(o => {
        const comment = comments ? comments.find(c => c.skillTagId === o.tagId) : null;
        improvements.push(buildImprovement(o, comment, context));
    })
    return improvements;
}

const loadImprovementActions = (checkup, comments, context, catalogs) => {
    getImprovements(context.appContext.userData.userProfile.id, context.appContext.talent.id, context.review.id, context.appContext.userData.token).then(response => {
        if( response.status === 200 && response.data.code === 202 && Array.isArray(response.data.result) && response.data.result.length > 0 ) {
            checkup.improvements = getImprovementInit(response.data.result, context);
        } else {
            checkup.improvements = getImprovementInit(null, context);
        }
        loadCheckup(checkup, comments, context, catalogs);
    }).catch(error => {
        console.log(error);
        checkup.improvements = getImprovementInit(null, context);
        context.setCheckup(checkup);
        context.setCheckupCache(JSON.stringify({ ...checkup }));
    })
}

const loadFeedbackComments = (checkup, comments, context, catalogs) => {
    checkup.feedbackComments = []
    getComments(context.appContext.userData.userProfile.id, context.appContext.talent.id, context.review.id, context.appContext.userData.token).then(response => {
        if( response.status === 200 && response.data.code === 202 && Array.isArray(response.data.result) && response.data.result.length > 0 ) {
            checkup.feedbackComments = response.data.result;
        } else {
            checkup.feedbackComments = [
                { typeId: 1, comments: '' },
                { typeId: 2, comments: '' }
            ]
        }
        loadImprovementActions(checkup, comments, context, catalogs);
    }).catch(error => {
        console.log(error);
        context.setCheckup(checkup);
        context.setCheckupCache(JSON.stringify(checkup));
    })
}

const loadQuestions = (checkup, context, catalogs) => {
    getCheckUpQuestions(context.appContext.userData.token).then( response => {
        if( response.status === 200 && response.data && response.data.code === 202 && response.data.result && Array.isArray(response.data.result) ) {
            loadFeedbackComments(checkup, response.data.result, context, catalogs);
        } else {
            context.setCheckup(checkup);
            context.setCheckupCache(JSON.stringify(checkup));
        }
    }).catch( error => {
        console.log(error);
        context.setCheckupCache(JSON.stringify(checkup));
    })
}

const loadWorkEnviroiment = (context, catalogs) => {
    const newCheckup = { ...context.checkup }
    getWorkEnv(context.appContext.talent.id, context.appContext.userData.token).then(response => {
        if(response.status === 200 && response.data.code === 202) {
            const { data } = response;
            newCheckup.workEnviroimentId = data.result.talentId;
            newCheckup.clasification = catalogs[0].find( c => c.id === data.result.classificationId );
            newCheckup.existRisk = catalogs[1].find( c => c.id === data.result.exitRisk );
            context.setCheckup(newCheckup);
        }
        loadQuestions(newCheckup, context, catalogs);
    }).catch(error => {
        loadQuestions(newCheckup, context, catalogs);
        console.log(error);
    })
}

const loadInformation = (context, catalogs) => {
    loadWorkEnviroiment(context, catalogs);
}

export const executeMultiPromises = context => {
    context.appContext.showLoading(true, context.appContext.t('loading'), context.style);
    Promise.all(context.requestArray).then( responses => {
        const catalogs = [];
        responses.forEach(({status, data}, index) => {
            if( status === 202 && data && data.code === 202 && data.result && data.result.content && Array.isArray(data.result.content) ) {
                let catTmp = [];
                data.result.content.map( l => {
                    const type = { id: l.catalogItemId, optionName: l.valueItem, description: l.description }
                    catTmp.push(type);
                })
                catalogs.push(catTmp);
                if(context.functions[index]) {
                    context.functions[index](catTmp);
                }
            }
        })
        if(catalogs.length === context.functions.length) {
            loadInformation(context, catalogs);
        }
    }).catch( error => {
        console.log(error);
    });
}