import { appApi, createJsonAccessToken } from './apiInstance';
import { talentStatus, candidateStatus } from '../core/hardcode';

const apiUrl = process.env.REACT_APP_GATEWAY_URL;

const mainContext = 'talent';
const catalogModule = 'catalog';
const skillModule = 'skills';
const feedbackModule = 'feedback';

export const getTalents = (accessToken, candidateMode, filter, page=0, size=10, statusFilter, dateFilter, langFilter, levelFilter, clientFilter) => {
    const filterParam = filter ? `&filterNameOrProfile=${filter}` : '';
    let filterStatus = '';
    if( statusFilter && statusFilter.length > 0 ) {
        statusFilter.forEach( s => filterStatus += `&statuses=${s.id}` )
    } else if( candidateMode ) {
        candidateStatus.forEach( statusId => filterStatus += `&statuses=${statusId}` )
    } else {
        talentStatus.forEach( statusId => filterStatus += `&statuses=${statusId}` )
    }
    const dateFilterParam = dateFilter && dateFilter.range ? `&startUpdatedAt=${dateFilter.range.start}&endUpdatedAt=${dateFilter.range.end}` : '';
    const langParam = langFilter ? `&languages=${langFilter.id}` : '';
    const levelParam = levelFilter ? `&langlevels=${levelFilter.id}` : '';
    let clientParam = '';
    if( clientFilter ) {
        clientFilter.forEach( c => clientParam += `&clientIds=${c.id}` )
    }
    const sorter = candidateMode ? 'updatedAt,DESC' : 'firstName,ASC';
    return appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${mainContext}/list?page=${page}&size=${size}&sort=${sorter}${filterStatus}${filterParam}${dateFilterParam}${langParam}${levelParam}${clientParam}`);
}
export const downloadCv = (talentId, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${mainContext}/${talentId}/downloadcv`, {responseType: 'blob'});
export const getSkillsFeedbackCatalog = (accessToken, talentId, page ,size) => appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${catalogModule}/${skillModule}/${feedbackModule}/${talentId}?page=${page}&size=${size}&sort=name&direction=ASC`);
export const setTalentStatus = (talentId, statusId, userId, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).put(`${mainContext}/${talentId}/setStatus?statusId=${statusId}&userId=${userId}`)
export const getPostulations = (talentId, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${mainContext}/${talentId}/application`);
export const getViewers = (talentId, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${mainContext}/${talentId}/viewers`);
export const saveViewers = (talentId, userId, viewerIds, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).post(`${mainContext}/${talentId}/viewers/save?userId=${userId}`, viewerIds);
export const saveWorkEnv = (talentId, request, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).post(`${mainContext}/${talentId}/work-environment`, request);
export const updateWorkEnv = (talentId, request, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).put(`${mainContext}/${talentId}/work-environment`, request);
export const getWorkEnv = (talentId, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${mainContext}/${talentId}/work-environment`);