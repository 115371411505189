import { 
    useContext,
    useEffect,
    useState, 
} from 'react';
import styles from '../../candidateInfo.module.scss';

import addIcon from '../../../../../assets/icons/add_circle.svg';
import pencilIcon from '../../../../../assets/icons/blue_pencil.svg';
import trashIcon from '../../../../../assets/icons/trash.svg';
import { selectEmtpy, yearsHardCode, yearsOrderAsc, yearsOrderDesc } from '../../../../core/hardcode';
import { CertificationModal } from './certification_modal';
import { objEquals, setEndAndValidityDates, sorterDate } from '../../../../core/helper';
import { ModalDelete } from '../../../../components/modal/modal_delete';
import { CandidateContext } from '../../context';
import { deleteCourse, getCourses } from '../../../../api/courseClient';
import { AppContext } from '../../context/AppProvider';
import { getCatalog } from '../../../../api/catalogClient';

const Certifications = ({
    resumeViewFlag,
    renderBackButton
}) => {

    const appContext = useContext(AppContext)

    const [catStudyLevel, setCatStudyLevel] = useState([]);

    const {
        setCertification,
        cleanCertification,
        certifications, 
        setCertifications,
        setCertificationsCache
    } = useContext( CandidateContext );

    const getCertifications = catStudyLevel => {
        appContext.showLoading(true, appContext.t('loading'), styles.no_scroll);
        getCourses(appContext.talent.id, appContext.userData.token).then((response) => {
            if( response.data && response.data.code === 201 && response.data.result ) {
                let certificationsTmp = [];
                response.data.result.map( certification => {
                    const ceWithDates = setSelectDates(certification);
                    const periodStart = ceWithDates.startDate ? `${ceWithDates.fiMes.description} ${ceWithDates.fiAnio.description}` : appContext.t('noData.notset');
                    const periodEnd = ceWithDates.endDate ? `${ceWithDates.ftMes.description} ${ceWithDates.ftAnio.description}` : appContext.t('noData.inProg');
                    const period = periodStart === appContext.t('noData.notset') && periodEnd === appContext.t('noData.inProg')
                        ? appContext.t('candidate.resume.courses.notPeriod')
                        : `${periodStart === appContext.t('noData.notset') ? '' : periodStart + ' - ' }${periodEnd}`;
                    ceWithDates.startDateParsed = periodStart;
                    ceWithDates.endDateParsed =  periodEnd;
                    ceWithDates.period = period;
                    ceWithDates.validityParsed =  (ceWithDates.validityDate ? `${ceWithDates.fvMes.description} ${ceWithDates.fvAnio.description}` : appContext.t('noData.notvalid'))
                    const studyLevel = catStudyLevel.find(s => s.id === `${certification.typeId}`)
                    ceWithDates.type = studyLevel && studyLevel.id ? { id: studyLevel.id, description: studyLevel.optionName } : selectEmtpy
                    certificationsTmp.push(ceWithDates);
                })
                const certificationsCurrents = certificationsTmp.filter( ce => !ce.validityDate );
                let certificationsLast = certificationsTmp.filter( ce => ce.validityDate !== null );
                certificationsLast = [ ...certificationsLast.sort((a, b) => sorterDate((a.ftanio ? a.ftAnio.id : -1), (a.ftMes ? a.ftMes.id : -1), (b.ftAnio ? b.ftAnio.id : -1) , (b.ftMes ? b.ftMes.id : -1), yearsOrderAsc)) ];
                certificationsTmp = [ ...certificationsCurrents ]
                certificationsTmp = [ ...certificationsTmp, ...certificationsLast ]
                setCertifications(certificationsTmp);
                setCertificationsCache(certificationsTmp);
                appContext.showLoading(false, '', styles.no_scroll);
            }
        }).catch((resp) => {
            appContext.showLoading(false, '', styles.no_scroll);
            console.log(resp)
        })
    }

    const deleteCertification = certification => {
        deleteCourse(appContext.talent.id, certification.courseId, appContext.userData.userProfile.id, appContext.userData.token).then((response) => {
            if( response.data && response.data.code === 201 && response.data.result ) {
                setCertifications( certifications.filter(ce => !objEquals(ce, certification) ) )
            }
        }).catch(() => {

        })
    }

    const setSelectDates = ce => {
        const yearHardCode1 = yearsHardCode(yearsOrderDesc);
        const yearHardCode2 = yearsHardCode(null);
        return setEndAndValidityDates(ce, yearHardCode1, yearHardCode2);
    }

    const handleOnClickShowModal = (certification, index) => {
        if ( certification ) {
            setCertification({
                ...certification,
                index: index
            });
        } else if (!certification) {
            cleanCertification();
        }
        appContext.setModalContent( <CertificationModal catStudyLevel={ catStudyLevel } /> )
        appContext.setModalFlag(true)
    }

    const handleOnClickRemove = certification => {
        if( certification.courseId ) {
            deleteCertification(certification);
        } else {
            setCertifications( certifications.filter(ce => !objEquals(ce, certification)) )
        }
        appContext.setModalFlag(false);
    }

    useEffect(() => {
        getCatalog(1000010053, 0 ,100, '', appContext.userData.token).then(({status, data}) => {
            if( status === 202 && data && data.code === 202 && data.result && data.result.content && Array.isArray(data.result.content) ) {
                const catStudyLevel = [];
                data.result.content.map( s => catStudyLevel.push({ id: s.catalogItemId, optionName: s.valueItem }) )
                setCatStudyLevel(catStudyLevel);
                getCertifications(catStudyLevel)
            }
        }).catch(error => {
            console.log(error);
        });
    }, []);

    const showDeleteModal = index => {
        const certification = certifications[index];
        appContext.setModalContent(
            <ModalDelete
                title={ `${certification.name} - ${certification.school}` }
                subtitle={ appContext.t('candidate.resume.courses.deleteMsg1') }
                paragrahp={ appContext.t('candidate.resume.courses.deleteMsg2') }
                onClick={ () => handleOnClickRemove(certification) }
                onClickCancel={ () => appContext.setModalFlag(false) }
            />
        )
        appContext.setModalFlag(true);
    }

    const renderCertifications = () => (
		certifications && certifications.map((ce, index) => (
            <div key={index} className={ styles.list_container }>
                <div className={ styles.list_items }>
                    <div className={ styles.item_grouped }>
                        <label>{ `${ce.name} - ${ce.school}` }</label>
                        <div className={ styles.action_container }>
                            <img src={ pencilIcon } alt="" onClick={ () => handleOnClickShowModal(ce, index) } />
                            <img className={ styles.trash } src={ trashIcon } alt="" onClick={ () => showDeleteModal(index) } />
                        </div>
                    </div>
                    <div className={ styles.certification_info }>
                        { ce.certificateNumber &&
                            <div className={ styles.info_container}>
                                <label>{ appContext.t('candidate.resume.courses.certNum') }:</label>
                                <p>{ ce.certificateNumber || appContext.t('candidate.resume.courses.notCertNum') }</p>
                            </div>
                        }
                        <div className={ styles.info_container}>
                            <label>{
                                ce.startDateParsed === appContext.t('noData.notset') && ce.endDateParsed !== appContext.t('noData.inProg')
                                ? appContext.t('candidate.resume.labelDate')
                                : appContext.t('candidate.resume.courses.period')
                            }:</label>
                            <p>{ ce.period }</p>
                        </div>
                        { ce.validityDate &&
                            <div className={ styles.info_container}>
                                <label>{ appContext.t('candidate.resume.courses.effective') }:</label>
                                <p>{ ce.validityParsed }</p>
                            </div>
                        }
                    </div>
                </div>
            </div>
        ))
	);

    const renderTitle = () => (<p className={ styles.title }>{ appContext.t('candidate.resume.courses.title') }</p>);

    return (
        <div className={ styles.form_container }>
            { appContext.isDesktopOrLaptop ? renderTitle() : ( resumeViewFlag ? renderTitle() : renderBackButton(appContext.t('candidate.resume.courses.title'))) }
            <div className={ styles.form_wrapper }>
                { renderCertifications() }
                <div className={ `${styles.form_row} ${styles.padded}` }>
                    <button className={ styles.add_info } onClick={() => handleOnClickShowModal(null)}>
                        { appContext.t('candidate.resume.courses.labelAdd') }
                        <img src={ addIcon } alt="" />
                    </button>
                </div>
            </div>
        </div>
    )
}
export default Certifications;