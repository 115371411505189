import { appApi, createJsonAccessToken } from "./apiInstance";

const apiUrl = process.env.REACT_APP_GATEWAY_URL;

const mainContext = 'talent';
const module = 'language';

export const newLanguage = (talentId, workExperience, userId, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).post(`${mainContext}/${talentId}/${module}/new?userId=${userId}`, workExperience);

export const editLanguage = (talentId, workExperience, userId, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).put(`${mainContext}/${talentId}/${module}/edit?userId=${userId}`, workExperience);

export const getLanguages = (talentId, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${mainContext}/${talentId}/${module}`);

export const deleteLanguage = (talentId, id, userId, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).delete(`${mainContext}/${talentId}/${module}/${id}/delete?userId=${userId}`);