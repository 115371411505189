import { useState, useRef } from 'react';
import styles from "../password_recovery.module.scss"
import stylesCandidate from '../../candidates/candidateInfo.module.scss';
import ReCAPTCHA from "react-google-recaptcha";
import { validateEmail, validateNotNull } from "../../../core/validators";
import { forgotPassword } from '../../../api/userClient';

const RecoveryForm = (props) => {
    const recaptcha = useRef();
    const captchaKey = process.env.REACT_APP_CAPTCHA_KEY;
    const { recoveryInfo, setRecoveryInfo, setStepIndex, appContext } = props;
    const [formErrors, setFormErrors] = useState(false)

    const validationFields = {
        username: [validateEmail, validateNotNull]
    }

    const handleFormChange = ({target}) => {
        setRecoveryInfo((prevData) => ({...prevData, [target.name]: target.value}))
    }

    const validateRequest = () => {
        const errorObject = {};
        var helper;
        // validating fields
        for(const field in validationFields) {
            for (const validator in validationFields[field]) {
                helper = validationFields[field][validator](recoveryInfo[field])
                if (helper) {
                    errorObject[field] = helper
                }
            }
        }
        return errorObject
    }

    const handleSubmit = event => {
        event.preventDefault();
        const validationErrors = validateRequest();
        // validando captcha
        if (!recaptcha.current.getValue()) {
            validationErrors.captcha = [appContext.t('validator.checkbox')]
        }
        if(Object.keys(validationErrors).length === 0) {
            appContext.showLoading(true, appContext.t('loading'), stylesCandidate.no_scroll);
            forgotPassword(recoveryInfo.username).then((response) => {
                appContext.showLoading(false, '', stylesCandidate.no_scroll);
                if (response.status === 200 && response.data.code === 200) {
                    appContext.displayNotification(appContext.t('recovery.vcsent'));
                    setStepIndex(1);
                } else if (response.status === 202 && response.data.code === 400) {
                    appContext.setModalFlag(true);
                    setStepIndex(1);
                }
            }).catch(error => {
                appContext.showLoading(false, '', stylesCandidate.no_scroll);
                if( error.code === 'ERR_NETWORK' ) {
                    appContext.displayNotification(null, true, true);
                }
            }) 
        } else {
            setFormErrors(validationErrors)
        }
    }

    return (
        <div className={ styles.recovery_form_wrapper }>
            <p className={ styles.title }>{appContext.t('recovery.title')}</p>
            <div className={ styles.instructions}>
                <p>{appContext.t('recovery.instructions')}</p>
            </div>
            <div className={ styles.form_wrapper }>
                <div className={ styles.data_wrapper }>
                    <form className={ styles.password_recovery } onChange={ (event) => handleFormChange(event) }>
                        <div className={ styles.form_field }>
                            <label>{appContext.t('login.mail')}</label>
                            <input className={Object.keys(formErrors).includes("username") ? styles.error : undefined} type="text" name="username" />
                            {
                                Object.keys(formErrors).includes("username") && (
                                    <p className={ styles.error_message }>{ formErrors.username[0] }</p>
                                )
                            }
                        </div>
                        <div className={ styles.centered_field }>
                            <ReCAPTCHA
                                sitekey={ captchaKey }
                                ref={ recaptcha }
                            />
                            {
                                Object.keys(formErrors).includes("captcha") && (
                                    <p className={ styles.error_message }>{ formErrors.captcha[0] }</p>
                                )
                            }
                        </div>
                        <div className={styles.recovery_button }>
                            <button className={ styles.active } type="submit " onClick={(event) => handleSubmit(event) }>{appContext.t('recovery.button')}</button>
                        </div>
                        <hr />
                        <div className={ styles.centered_field }>
                            <p>{appContext.t('recovery.labelBack')}</p>
                            <a href="/login">{appContext.t('recovery.labelUrlBack')}</a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default RecoveryForm