export const currentYear = new Date().getFullYear()
export const selectEmtpy = { id: 0, description: '' };

export const FEEDBACK_DESC = [
	'Desempeño insatisfactorio',
	'No cumple con las expectativas',
	'Cumple con las expectativas',
	'Supera las expectativas',
	'Desempeño distinguido'
];

export const MONTHS = [
	{ id: 1, optionName: "enero"},
	{ id: 2, optionName: "febrero"},
	{ id: 3, optionName: "marzo"},
	{ id: 4, optionName: "abril"},
	{ id: 5, optionName: "mayo"},
	{ id: 6, optionName: "junio"},
	{ id: 7, optionName: "julio"},
	{ id: 8, optionName: "agosto"},
	{ id: 9, optionName: "septiembre"},
	{ id: 10, optionName: "octubre"},
	{ id: 11, optionName: "noviembre"},
	{ id: 12, optionName: "diciembre"},
];

const year = {
	min: 1940,
	max: 2050
}

export const yearsOrder = {
	asc: 'asc',
	desc: 'desc',
	full: 'full'
}

export const yearsHardCode = order => {
	const years = [];
	let id = 1;
	if( order === yearsOrder.desc ) {
		for (let index = currentYear; index >= year.min; index--) {
			const element = {
				id: id++,
				optionName: `${index}`
			}
			years.push(element);
		}
	} else if ( order === yearsOrder.asc ) {
		for (let index = currentYear; index <= year.max; index++) {
			const element = {
				id: id++,
				optionName: `${index}`
			}
			years.push(element);
		}
	} else if ( order === yearsOrder.full ) {
		for (let index = year.min; index <= year.max; index++) {
			const element = {
				id: id++,
				optionName: `${index}`
			}
			years.push(element);
		}
	} else {
		console.log(order, 'not supported!');
	}
	return years;
}

export const hardSkillsCatalogId=1001100001
export const softSkillsCatalogId=1001100002
export const workSkillsCatalogId=1001100003
export const softwareCatalogId=1001100004