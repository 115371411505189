import styles from '../checkup.module.scss';
import stylesC from '../../candidates/candidateInfo.module.scss';
import { Climate } from './climate/Climate';
import { FeedbackCommentsResume } from './comments/FeedbackCommentsResume';
import { ImprovementActions } from './improvement/ImprovementActions';
import { InternalNotes } from './notes/InternalNotes';
import emailIcon from '../../../../assets/icons/email.svg';
import { ModalSendLeader } from './ModalSendLeader';
import { onUpdateStatus } from '../thunks/saveThunks';

export const CheckupResume = ({
    appContext,
    tap,
    changeTap,
    review,
    setReview,
    reviews,
    setReviews,
    checkup,
    catClasification,
    catExistRisk,
    catLevelSatisfaction,
    modeRead,
    setModeRead
}) => {

    const updateStatus = () => {
        onUpdateStatus({
            "appContext": appContext,
            "review": review,
            "setReview": setReview,
            "checkup": checkup,
            "reviews": reviews,
            "setReviews": setReviews,
            statusId: 1000530003,
            message: 'checkUp.report.sendMessage'
        });
    }

    const onClickSendEmail = () => {
        const noShowModal = localStorage.getItem('noShowCheckUpModalSendLeader');
        if(noShowModal) {
            updateStatus(appContext.userData.userProfile.id, appContext.talent.id, review.id, 1000530003);
        } else {
            appContext.setModalFlag(!appContext.modalFlag);
            appContext.setModalContent(
                <ModalSendLeader
                    appContext={appContext}
                    review={review}
                    updateStatus={updateStatus}
                />
            );
        }
    }

    const renderHeader = () => (
        <div className={ `${styles.section} ${styles.gap8}` }>
            <div className={ styles.header }>
                <span className={ styles.title }>{ appContext.t('checkUp.report.title') }</span>
                <div className={ `${styles.button_orange}` } onClick={ onClickSendEmail }>
                    {appContext.t('checkUp.report.sendButtonLabel')}
                    <img src={ emailIcon } width={ 24 } height={ 24 }  alt="" />
                </div>
            </div>
            <span>{ appContext.t('checkUp.report.talentEvaluated') }: {appContext.talent.firstName} {appContext.talent.lastName}</span>
            <span>{ appContext.t('candidate.resume.review.reviewer') }: {review.authorName}</span>
            <span>{ appContext.t('checkUp.report.createdBy') }: {checkup.creator}</span>
        </div>
    )

    return (
        <div>
            <div className={ `${stylesC.form_container} ${styles.checkup}` }>
                { renderHeader() }
                <Climate
                    appContext={appContext}
                    tap={tap}
                    checkup={checkup}
                    changeTap={changeTap}
                    catClasification={catClasification}
                    catExistRisk={catExistRisk}
                    catLevelSatisfaction={catLevelSatisfaction}
                    modeResume
                />
                <FeedbackCommentsResume
                    appContext={appContext}
                    changeTap={changeTap}
                    review={review}
                    checkup={checkup}
                />
                <ImprovementActions
                    appContext={appContext}
                    checkup={checkup}
                    modeResume
                    changeTap={changeTap}
                    modeRead={modeRead}
                    setModeRead={setModeRead}
                />
                <InternalNotes
                    appContext={appContext}
                    checkup={checkup}
                    modeResume
                    changeTap={changeTap}
                    modeRead={modeRead}
                    setModeRead={setModeRead}
                />
            </div>
        </div>
    )
}
