import { useContext, useEffect, useState } from 'react';
import { Searcher } from '../../../../components/talent-module/searcher/Searcher';
import styles from '../../../talents/talentInfo.module.scss';
import reloadIcon from '../../../../../assets/icons/reload.svg';
import trashIcon from '../../../../../assets/icons/trash.svg';
import infoIcon from '../../../../../assets/icons/info_icon_small.svg';
import closeIcon from '../../../../../assets/icons/close_small.svg';
import talentListNotFound from '../../../../../assets/img/talent_list_not_found.png';
import talentListNotMatch from '../../../../../assets/img/talent_list_not_match.png';
import { Pagination } from '../../../../components/talent-module/pagination/Pagination';
import { Loading } from '../../../../components/talent-module/loading/Loading';
import CandidateInfo, { LoadedFrom } from '../../candidateInfo';
import { AppContext } from '../../context/AppProvider';
import { newSkill, selectEmtpy } from '../../../../core/hardcode';
import { CandidateContext } from '../../context';
import { deleteSkillTag, deleteSkillTagValidation, getSkillsTag } from '../../../../api/skillClient';
import { SelectMultiple } from '../../../../components/talent-module/searcher/SelectMultiple';
import { objEquals } from '../../../../core/helper';
import { getCatalog } from '../../../../api/catalogClient';
import { softwareCatalogId } from '../../../../core/talent-module/hardcode';
import { SkillModal } from './SkillModal';
import { defaultStringEmpty } from '../../../../core/talent-module/helper';

export const SkillList = () => {

  const appContext = useContext(AppContext);
  const {
    setCompetition,
    setCompetitionErrors
} = useContext(CandidateContext);

  const [catType, setCatType] = useState([]);
  const [modal, setModal] = useState();
  const [skills, setSkills] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [showReload, setShowReload] = useState(false);
  const [showTooltipSuggestion, setShowTooltipSuggestion] = useState(false);

  const loadCatTypes = () => {
    getCatalog(1000010011, 0 ,200, '&sort=valueItem&direction=ASC', appContext.userData.token).then(({status, data}) => {
        if( status === 202 && data && data.code === 202 && data.result && data.result.content && Array.isArray(data.result.content) ) {
            let typesTmp = [];
            data.result.content.forEach( l => {
                const type = { id: l.catalogItemId, optionName: l.valueItem }
                typesTmp.push(type);
            })
            setCatType(typesTmp);
            fetchSkills(appContext.filter, appContext.filterType, appContext.visibilityFilter, appContext.transFilter, appContext.currentPage, typesTmp);
        }
    }).catch((error) => {
        console.log(error);
    })
  }

  const fetchSkills = (filter, filterType, visibilityFilter, transFilter, currentPage, catType) => {
    setTotal(0);
    setSkills([]);
    setModal(<Loading />);
    getSkillsTag(appContext.userData.token, filter, filterType, visibilityFilter, transFilter, currentPage, 10).then(({status, data}) => {
      if( status === 200 && data && data.content && Array.isArray(data.content) ) {
        const skillsTmp = [];
        data.content.forEach(s => {
          const type = catType.find( c => c.id === s.categoryId);
          const skill = {
            id: s.id,
            name: s.name,
            categoryId: s.categoryId,
            category: type ? type.optionName : '',
            isPublic: appContext.t(`candidate.resume.skills.list.labelVisibility${ s.isPublic ? 'Yes' : 'No' }`)
          }
          if( s.traductions && s.traductions.length > 0 ) {
            const en = s.traductions.find( t => t.languageId === 1 );
            /* skill['name'] = skill.name; */ // ? skill.name : ( en && en.name );
            skill['translate'] = en && en.name;
          }
          skillsTmp.push(skill)
        })
        setSkills(skillsTmp);
        setTotalPages(data.totalPages);
        setTotal(data.totalElements);
        setShowReload(data.totalElements === 0 || filter !== '' || filterType.length > 0 || visibilityFilter.length > 0 || transFilter.length > 0);
        setModal(null);
      } else {
        setModal(null);
        setSkills([]);
        setTotalPages(0);
      }
    }).catch( error => {
      console.log(error);
      setModal(null);
      setSkills([]);
      setTotalPages(0);
    })
  }

  const deleteSkill = (skillTag) => {
    deleteSkillTag(appContext.userData.userProfile.id, skillTag.id, 'false', appContext.userData.token).then(response => {
      if( response.status === 200 && response.data && response.data.code === 200) {
        fetchSkills(appContext.filter, appContext.filterType, appContext.visibilityFilter, appContext.transFilter, appContext.currentPage, catType);
        appContext.displayNotification(appContext.t('candidate.resume.skills.delete.deleted'));
      }
    }).catch(error => {
      console.log('Error to delete SkillTag', error);
    })
  }

  useEffect(() => {
    loadCatTypes()
  }, [appContext.filter, appContext.filterType, appContext.visibilityFilter, appContext.transFilter]);

  const onClickDetail = skill => {
    appContext.setTalent(null);
    appContext.contentScrollUp();
    const title1 = appContext.t(`candidate.resume.skills.addForm.title${ skill ? 'Edit' : 'Create' }`);
    const title2 = (!skill || (skill && skill.categoryId !== softwareCatalogId ))
      ? appContext.t('candidate.resume.skills.addForm.titleSkill')
      : appContext.t('candidate.resume.soft.title');
    appContext.setMenuNavigation([...appContext.menuNavigation, { label: `${title1} ${title2}` }]);
    appContext.setHomeContent(<CandidateInfo tab={ 18 } rvf from={ LoadedFrom.SKILLS } />)
    setCompetition({
      ...newSkill,
      id: skill?.id,
      name: skill ? skill.name : '',
      category: { ...selectEmtpy, id: skill ? skill.categoryId : 0 },
      categoryId: skill ? skill.categoryId : undefined
    });
    setCompetitionErrors({});
  }

  const onClickDelete = skill => {
    deleteSkillTagValidation(skill.id, appContext.userData.token).then( response => {
      if( response.status === 200 && response.data) {
        if( response.data.code === 400 ) {
          appContext.setModalFlag(!appContext.modalFlag);
          appContext.setModalContent(
            <SkillModal
              title={ appContext.t('candidate.resume.skills.delete.modalConfirmTitle') }
              message={ response.data.result }
              skillTag={ skill }
            />
          );
        } else if( response.data.code === 200 ) {
          appContext.setModalFlag(!appContext.modalFlag);
          appContext.setModalContent(
            <SkillModal
              title={ appContext.t('candidate.resume.skills.delete.modalConfirmTitle') }
              message={ <p><b>{ defaultStringEmpty(skill.name) }</b> { appContext.t('candidate.resume.skills.delete.modalConfirmMsg') }</p> }
              labelBtnConfirm={ appContext.t('candidate.resume.skills.delete.modalConfirmBtn') }
              skillTag={ skill }
              deleteFunction={ deleteSkill }
            />
          );
        }
      }
    }).catch( error => {
      console.log('Error to delete competition', error);
    })
  }
  
  const onKeyDownSearch = ({key}) => {
    const isEmpty = key === 'Backspace' && appContext.filter.length === 1;
    if ( key === 'Enter' || isEmpty ) {
      setShowReload(!isEmpty);
      onClickSearch(isEmpty ? '' : appContext.filter);
    }
  }

  const onChangeSearch = ({target}) => {
    appContext.setFilter(target.value);
  }
  
  const onClickSearch = (filter) => {
    appContext.setCurrentPage(0);
    fetchSkills(filter, appContext.filterType, appContext.visibilityFilter, appContext.transFilter, 0, catType);
  }

  const reload = () => {
    setShowReload(false);
    appContext.setFilter('');
    appContext.setCurrentPage(0);
    appContext.setFilterType([]);
    appContext.setVisibilityFilter([]);
    appContext.setTransFilter([]);
    fetchSkills('', [], [], [], 0, catType);
  }

  const onClickBackPage = () => {
    if( appContext.currentPage > 0 ) {
      appContext.setCurrentPage(appContext.currentPage - 1);
      fetchSkills(appContext.filter, appContext.filterType, appContext.visibilityFilter, appContext.transFilter, appContext.currentPage - 1, catType);
    }
  }

  const onClickForwardPage = cP => {
    if( appContext.currentPage + cP < totalPages ) {
      appContext.setCurrentPage(appContext.currentPage + 1);
      fetchSkills(appContext.filter, appContext.filterType, appContext.visibilityFilter, appContext.transFilter, appContext.currentPage + 1, catType);
    }
  }

  const handleOnBlur = () => setTimeout(() => setShowTooltipSuggestion(false), 100);

  const renderTooltipSuggestion = () => showTooltipSuggestion && (
    <div className={ styles.suggestion_tooltip }>
      <div className={ styles.suggestion_tooltip_icon }>
        <img  src={ infoIcon } alt='' />
      </div>
      <div>
        <label className={ styles.suggestion_tooltip_textbold }>{ appContext.t('candidate.resume.skills.list.tooltipSug.bold') }</label>
        &nbsp;
        { appContext.t('candidate.resume.skills.list.tooltipSug.label') }
      </div>
      <div>
        <img  src={ closeIcon } alt='' onClick={ () => setShowTooltipSuggestion(!showTooltipSuggestion) } />
      </div>
    </div>
  )

  const onClickSelectFilter = (option, value, setValue) => {
    const newValue = value.find( v => objEquals(v,option) ) ? value.filter( v => v.id !== option.id ) : [ ...value, option ];
    setValue(newValue);
  }

  const renderSelectsFilter = () => (
    <div className={ styles.container_list_sel }>
      <SelectMultiple
        name='filterType'
        value={ appContext.filterType }
        setValue={ appContext.setFilterType }
        options = { catType }
        placeholder = { appContext.t(`candidate.resume.skills.list.labelTypeFilter`) }
        onChange = { onClickSelectFilter }
      />
      <SelectMultiple
        name='visibilityFilter'
        value={ appContext.visibilityFilter }
        setValue={ appContext.setVisibilityFilter }
        options = {[
          { id: 1, optionName: appContext.t('candidate.resume.skills.list.labelVisibilityOn') },
          { id: 2, optionName: appContext.t('candidate.resume.skills.list.labelVisibilityOff') }
        ]}
        placeholder = { appContext.t(`candidate.resume.skills.list.labelVisibilityFilter`) }
        onChange = { onClickSelectFilter }
      />
      <SelectMultiple
        name='transFilter'
        value = { appContext.transFilter }
        setValue={ appContext.setTransFilter }
        options = {[
          { id: 1, optionName: appContext.t('candidate.resume.skills.list.labelTransWith') },
          { id: 2, optionName: appContext.t('candidate.resume.skills.list.labelTransWithout') }
        ]}
        placeholder = { appContext.t(`candidate.resume.skills.list.labelTransFilter`) }
        onChange = { onClickSelectFilter }
      />
    </div>
  )

  const renderBtext = bText => bText && <label className={ styles.pharagrap_textbold }> {bText}</label>

  const renderNotFound = () => {
    let title = '';
    let subtitle = '';
    let bText = undefined;
    let imgSrc = null;
    if( skills.length === 0 && appContext.filter === '' ) {
      title = appContext.t('candidate.resume.skills.list.notFound')
      subtitle = appContext.t('candidate.resume.skills.list.notFoundLabel')
      bText = appContext.t('candidate.resume.skills.list.labelBtnAdd')
      imgSrc = talentListNotFound;
    } else if (skills.length === 0 && appContext.filter !== '') {
      title = appContext.t('candidate.resume.skills.list.notMatch')
      subtitle = appContext.t('candidate.resume.skills.list.notMatchLabel')
      imgSrc = talentListNotMatch;
    }
    return (
      <div className={ styles.not_found }>
        <label>{ title }</label>
        <p className={ `${styles.pharagrap} ${styles.pharagrap_simple}` }>{ subtitle }{ renderBtext(bText) }</p>
        <img src={ imgSrc } alt='' />
      </div>
    )
  }

  const renderItems = () => skills.map( (skill, index) => (
    <tr key={ index }>
      <td className={ `${styles.first_child_border} ${styles.first_child_border_row}` } onClick={ () => onClickDetail(skill) }>{ skill.name }</td>
      <td className={ styles.textleft } onClick={ () => onClickDetail(skill) }>{ skill.category }</td>
      <td className={ styles.textleft } onClick={ () => onClickDetail(skill) }>
        <label className={ !skill.translate ? styles.fontgray : undefined }>{ skill.translate ? skill.translate : appContext.t('candidate.resume.skills.list.noTranslateEN') }</label>
      </td>
      <td className={ styles.textcenter } onClick={ () => onClickDetail(skill) }>{ skill.isPublic }</td>
      <td className={ `${styles.last_child_border} ${styles.last_child_border_row}` } onClick={ () => onClickDelete(skill) }>
        <div className={ styles.content_center }>
          <div className={ styles.trash }>
            <img  src={ trashIcon } alt='' />
          </div>
        </div>
      </td>
    </tr>
  ) );

  const renderTable = () => (
    <table>
      <thead>
        <tr>
          <th className={ `${styles.first_child_border} ${styles.textleft}` } style={{ width: '35%' }}>{appContext.t('candidate.resume.skills.list.headers.name')}</th>
          <th className={ styles.textleft } style={{ width: '10%' }}>{appContext.t('candidate.resume.skills.list.headers.type')}</th>
          <th className={ styles.textleft } style={{ width: '35%' }}>{appContext.t('candidate.resume.skills.list.headers.translates')}</th>
          <th className={ styles.textleft } style={{ width: '5%', cursor: 'pointer' }} onClick={ () => setShowTooltipSuggestion(!showTooltipSuggestion) }>
            <div className={ styles.suggestion } tabIndex="1" onBlur={ handleOnBlur }>
              {appContext.t('candidate.resume.skills.list.headers.suggestion')}
              <img  src={ infoIcon } alt='' />
              { renderTooltipSuggestion() }
            </div>
          </th>
          <th className={ styles.last_child_border } style={{ width: '15%' }}>
            {appContext.t('candidate.resume.skills.list.headers.delete')}
          </th>
        </tr>
      </thead>
      <tbody>
        { renderItems() }
      </tbody>
    </table>
  )

  return (
    <div className={ styles.container } >
      <div className={ styles.wrapper }>
        <div className={ styles.container_list_btn }>
          <label className={ styles.title }>{ appContext.t('candidate.resume.skills.title') }</label>
          <div className={ styles.buttons }>
            <button onClick={() => onClickDetail(null) }>{ appContext.t('candidate.resume.skills.list.labelBtnAdd') }</button>
          </div>
        </div>
        <div className={ styles.container_list_btn }>
          <div className={ styles.btn_label }>
            <div className={ styles.width_40 }>
              <Searcher 
                name={'filter'} 
                value={ appContext.filter } 
                placeholder={ appContext.t('candidate.resume.skills.list.labelFilter') }
                onChange={ onChangeSearch }
                onKeyDown={ onKeyDownSearch }
                onClick={ () => onClickSearch(appContext.filter) }
              />
            </div>
            <label>{ `${total} ${appContext.t('candidate.resume.skills.list.labelTotal').replace('s', total === 1 ? '' : 's')}` }</label>
            { showReload && !modal &&  <div className={ styles.separator } /> }
            {
              showReload && !modal && (
                <div className={ styles.reload } onClick = { () => reload() }>
                  <img src={ reloadIcon } alt='' />
                  <label>{ appContext.t('candidate.resume.skills.list.labelReload') }</label>
                </div>
              )
            }
          </div>
        </div>
        { renderSelectsFilter() }
        { renderTable() }
        { !modal && skills.length === 0 && renderNotFound() }
        { modal }
        <Pagination
          totalElements={ skills.length }
          currentPage={ appContext.currentPage }
          totalPages={ totalPages }
          onClickBackPage={ onClickBackPage }
          onClickForwardPage={ onClickForwardPage }
        />
      </div>
    </div>
    
  )
}
