import styles from '../feedback.module.scss';

export const FeedbackSkills = ({
    catType, 
    skills
}) => {

    const renderCategoryCap = categoryId => (
        <div className={ styles.inline }>
            {
                skills?.filter(s => s.categoryId === categoryId).map( (s, index) => (
                    <div key={ index } className={ styles.e_item }>{ s.name }</div>
                ))
            }
        </div>
    )

    return (
        <div className={ styles.pharagrap }>
            {
                catType.map( ( category, index ) => {
                    return skills.filter(s => s.categoryId === category.id).length > 0 && (
                        <div key={ index } className={ styles.e_title }>
                            {category.optionName}
                            { renderCategoryCap(category.id) }
                        </div>
                    )
                })
            }
        </div>
    )
}
