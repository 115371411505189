import styles from '../../checkup.module.scss';
import stylesC from '../../../candidates/candidateInfo.module.scss';
import stylesF from '../../../feedback/feedback.module.scss';
import { FeedbackSkillPills } from '../../../feedback/components/FeedbackSkillPills';
import { TextArea } from '../../../../components/inputs/TextArea';
import { validateNotNull } from '../../../../core/validators';

export const validateFeedbackComments = checkup => {
    const commentToken = 'comment';
    const checkupErrors = {}
    let firstError = false;
    checkup.comments.forEach( (c, index) => {
        const commentValidation = validateNotNull(c.value.comment);
        if( commentValidation ) {
            checkupErrors[`${commentToken}_${index}`] = commentValidation;
            if(!firstError) {
                checkupErrors[`${commentToken}_${index}_focus`] = true;
                firstError = true;
            }
        }
    });
    return checkupErrors;
}

export const FeedbackComments = ({
    appContext,
    review,
    checkup,
    checkupErrors,
    onChangeInput,
    catType,
    modeResume
}) => {

    const onChangeComment = ( id, value ) => {
        const commentsTmp = [ ...checkup.feedbackComments ];
        const index = commentsTmp.findIndex( c => c.typeId === id );
        commentsTmp[index] = {
            ...commentsTmp[index],
            comments: value
        }
        onChangeInput('feedbackComments', commentsTmp);
    }

    const getCommentChild = typeId => {
        const comment = checkup.feedbackComments.find( c => c.typeId === typeId );
        return (
            <div className={styles.checkup}>
                <div className={styles.section}>
                    <div className={ styles.comment }>
                        <TextArea
                            cssCounter={ styles.subtitle_left }
                            name={ typeId }
                            label={''}
                            sublabel={ `${appContext.t('checkUp.climate.commentLabel')} ${appContext.t('paginator.of')} ${appContext.talent.firstName}*` }
                            cssSubtitle={ styles.sublabel }
                            maxLength={ 3000 }
                            errorLen={ 'validator.length' }
                            value={ comment && comment.comments || "" }
                            onChange={ onChangeComment }
                            error={ Object.keys(checkupErrors).includes(`feedback_${typeId}`) ? checkupErrors[`feedback_${typeId}`][0] : '' }
                            focus={ Object.keys(checkupErrors).includes(`feedback_${typeId}_focus`) }
                        />
                    </div>
                </div>
            </div>
        )
    }

    const renderSkillsSection = () => {
        const skillSections = []
        if( review.strengths && review.strengths.length > 0 ) {
            skillSections.push({
                title: appContext.t('candidate.resume.review.strength'),
                skills: review.strengths,
                comment: review.strengthsComment,
                labelNoSkills: appContext.t('candidate.resume.review.notStrength'),
                commentChild: getCommentChild(1)
            })
        }
        if( review.opportunities && review.opportunities.length > 0 ) {
            skillSections.push({
                title: appContext.t('candidate.resume.review.opportunities'),
                skills: review.opportunities,
                comment: review.opportunitiesComment,
                labelNoSkills: appContext.t('candidate.resume.review.notOpportunities'),
                commentChild: getCommentChild(2)
            })
        }
        return <FeedbackSkillPills
            appContext={appContext}
            skillSections={skillSections}
            catType={catType}
            customLabelComment={ appContext.t('checkUp.comments.leaderComment') }
        />
    }

    return (
        <div>
            <div className={ `${stylesC.form_container} ${styles.checkup}` }>
                { !modeResume && <p className={ stylesC.title }>{ appContext.t('candidate.resume.review.checkUpAdd') }</p> }
                <div className={ stylesC.section_header}>
                    <div className={ stylesC.section_title }>
                        <div className={ stylesC.decorator }></div>
                        { appContext.t('checkUp.comments.title') }
                    </div>
                </div>
            </div>
            <div className={ stylesF.feedback }>{ checkup.feedbackComments && renderSkillsSection() }</div>
        </div>
    )
}
