import { useEffect } from 'react'
import { selectEmtpy } from '../../core/hardcode'
import styles from './input.module.scss'

export const Radio = ({
    name,
    value = selectEmtpy,
    label,
    className,
    options = [],
    onClick
}) => {

    useEffect(() => {
    }, [value])
    
    const onClickHandler = value => {
        onClick(name, value);
    }

    const renderOptions = () => options.map((o, index) => (
        <div key={ index }
            className={ `${styles.radio_item} ${ o.id === value.id ? styles.radio_item_selected : undefined }` }
            onClick={ () => onClickHandler(o) }>
            { o.optionName }
        </div>
    ))

    return (
        <div className={ className }>
            <label>{label}</label>
            { options && <div className={ styles.radio }>{ renderOptions() }</div> }
        </div>
    )
}
