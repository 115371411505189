import { useEffect, useState } from 'react';
import styles from './input.module.scss';

export const BarEvaluation = ({
    classMain,
    title,
    name,
    value,
    options,
    onClick,
    width,
    modeRead = false
}) => {

    const [label, setLabel] = useState();
    const [backgroundColor, setBackgroundColor] = useState();
    const [currentSelected, setCurrentSelected] = useState()
    
    useEffect(() => {
        const optionSelected = options.find( l => l.id === value.id);
        if( optionSelected ) {
            setLabel(title ? title : optionSelected.optionName);
            setBackgroundColor(optionSelected.description);
        }
        setCurrentSelected(options.findIndex( o => o.id === value.id ) + 1);
    }, [value, options])
    

    const squareSelected = (level, arrayLevel) => {
        const styleSelected = {}
        if(level && arrayLevel <= level) {
            styleSelected.backgroundColor = backgroundColor;
        }
        return styleSelected;
    }

    const simpleSelected = () => {
        const styleSelected = {}
        const perc = 100 / options.length;
        const optionSelected = options.find(o => o.id === value.id);
        if( optionSelected ) {
            styleSelected.backgroundColor = optionSelected.description;
            styleSelected.width = `${perc * currentSelected}%`
        }
        return styleSelected;
    }

    const renderSimpleBar = () => (
        <div className={ styles.bar_simple }>
            <div className={ styles.progress } style={simpleSelected()}></div>
        </div>
    )

    const renderSquareBar = () => options.map((o, index) => (
        <div key={ index } style={squareSelected(value.id, o.id)}
            className={ `${styles.square} ${ index === 0 ? styles.square_before : undefined } ${ index === options.length - 1 ? styles.square_last : undefined}` }
            onClick={ () => onClick(name, o.id) }
        />
    ))

    const renderInput = () => (
        <div className={ classMain }>
            <div className={ styles.bar }>{ renderSquareBar() }</div>
            { label }
        </div>
    )

    const renderReader = () => (
        <div className={ classMain }>
            <span>{ label }</span>
            <div className={ styles.bar_line } style={ width ? { width: `${width}%` } : {} }>
                { renderSimpleBar() }
                <span>{ currentSelected }/{ options.length }</span>
            </div>
        </div>
    )

    return modeRead ? renderReader() : renderInput();
}
