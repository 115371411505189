import styles from '../feedback.module.scss';
import { FeedbackSkills } from './FeedbackSkills';

export const FeedbackSkillPills = ({
    appContext,
    skillSections,
    catType,
    customLabelComment
}) => {

    const renderSkillsDetail = section => (
        <div className={ styles.content }>
            { section.skills.length > 0 ? <FeedbackSkills catType={ catType } skills={ section.skills } /> : <p>{section.labelNoSkills}</p> }
            { section.skills.length > 0 &&
                <div className={ styles.pharagrap }>
                    <div className={ styles.e_title }>{ customLabelComment ? customLabelComment : `${appContext.t('candidate.resume.review.commentOn')} ${section.title}` }</div>
                    <p>{ !section.comment || section.comment === '' ? `${appContext.t('candidate.resume.review.noComment')} ${section.title}` : section.comment}</p>
                </div>
            }
            { section.commentChild }
        </div>
    )

    const renderDetailSection = (index, section) => (
        <div key={index} className={ styles.section }>
            <div className={ styles.title }><h2 className={ styles.p_title }>{ section.title }</h2></div>
            <div className={ styles.content }>
                { renderSkillsDetail(section) }
            </div>
        </div>
    )

    return <div className={ styles.content }>{ skillSections.map((section, index) => renderDetailSection(index, section)) }</div>
}
