import Axios  from 'axios';
import { useNavigate } from 'react-router-dom';

export const createJsonAccessToken = accessToken => ({
    'accessToken': accessToken
})

const getHeaders = headers => {
    const objectHeaders = {
        'Accept-Language': 'es-MX',
        'Content-Type': 'application/json'
    }
    if( headers?.contentType ) {
        objectHeaders['Content-type'] = headers.contentType;
    }
    // If there is token, add X-Authorization header
    if( headers?.accessToken ) {
        objectHeaders['X-Authorization'] = `Bearer ${headers.accessToken}`
    }
    if( headers?.token ) {
        objectHeaders.token = headers.token
    }
    if( headers?.refreshtoken ) {
        objectHeaders.refreshtoken = headers.refreshtoken
    }
    if( headers?.authorization ) {
        objectHeaders['Authorization'] = headers.authorization
    }
    return objectHeaders;
    }

export const appApi = (url, headers) => {
    
    const axiosInstance = Axios.create({
        baseURL: url,
        headers: getHeaders(headers)
    })

    axiosInstance.interceptors.response.use(response => response, error => {
        if (error) {
            if (error.response?.status === 401 || error.response?.status === 403) {
                localStorage.removeItem('userInfo');
                const navigate = useNavigate();
                navigate("/login")
            }
            return Promise.reject(error);
        }
    });
    return axiosInstance;
}