import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../candidates/context/AppProvider';
import { Searcher } from '../../../components/talent-module/searcher/Searcher';
import styles from '../../talents/talentInfo.module.scss';
import reloadIcon from '../../../../assets/icons/reload.svg';
import talentListNotFound from '../../../../assets/img/talent_list_not_found.png';
import talentListNotMatch from '../../../../assets/img/talent_list_not_match.png';
import { Pagination } from '../../../components/talent-module/pagination/Pagination';
import { Loading } from '../../../components/talent-module/loading/Loading';
import { SelectMultiple } from '../../../components/talent-module/searcher/SelectMultiple';
import { objEquals, setSkillSelecValue } from '../../../core/helper';
import { getClientCatalog, getProfile, getProfiles, getSkillsCandidateCatalog } from '../../../api/catalogClient';
import { defaultStringEmpty } from '../../../core/talent-module/helper';
import { ProfileDetail } from './components/ProfileDetail';
import { CatalogContext } from '../context';
import { selectEmtpy } from '../../../core/hardcode';

export const ProfileList = () => {

  const appContext = useContext(AppContext);
  const {
    setProfile,
    setProfileCache,
    cleanProfile
  } = useContext(CatalogContext);

  const [catClient, setCatClient] = useState([]);
  const [catSkills, setCatSkills] = useState([]);
  const [modal, setModal] = useState();
  const [profiles, setProfiles] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [showReload, setShowReload] = useState(false);

  const loadCatClient = () => {
    getClientCatalog(appContext.userData.token).then( response => {
      if( response.status === 200 && response.data && response.data.code === 200 && response.data.result && Array.isArray(response.data.result) ) {
        let clientsTmp = [];
        response.data.result.map( l => clientsTmp.push({ id: l.id, optionName: l.name}))
        setCatClient(clientsTmp);
        fetchProfiles(appContext.filter, appContext.clientFilter, appContext.visibilityFilter, appContext.currentPage, clientsTmp);
    }
    }).catch( error => {
      console.log(error);
    })
  }

  const loadCatSkills = () => {
    getSkillsCandidateCatalog(0, 10000, appContext.userData.token).then((response) => {
        if( response.status === 200 && response.data && response.data.content && Array.isArray(response.data.content) ) {
            let catTmp = [];
            response.data.content.map( r => {
                catTmp.push({ id: r.id ,optionName: r.name, categoryId: r.categoryId, percentage: r.skillLevelPercentage});
            })
            setCatSkills(catTmp);
        }
    }).catch(error => {
        console.log(error)
    })
  }

  const loadProfile = profile => {
    if( profile.id ) {
        getProfile(profile.id, appContext.userData.token).then(response => {
            if( response.data && response.data.code === 200 && response.data.result ) {
                let skillsTmp = [];
                response.data.result.skills.map( (skill, index) => {
                    const skillWithTagValue = setSkillSelecValue(skill, index, catSkills, true);
                    skillWithTagValue.tagId = skill.id;
                    skillsTmp.push(skillWithTagValue);
                })
                skillsTmp = skillsTmp.sort( (a, b) => sorterAlphAsc(a,b) );
                const profileTmp = { ...profile, skills: skillsTmp};
                setProfile(profileTmp);
                setProfileCache(profileTmp);
            }
        }).catch(error => {
            console.log(error)
        })
    }
  }

  const sorterAlphAsc = (a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if( nameA < nameB ) {
        return -1;
    }
    if( nameA > nameB ) {
        return 1;
    }
    return 0;
  }

  const fetchProfiles = (filter, clientFilter, visibilityFilter, currentPage, catClient) => {
    setTotal(0);
    setTotalPages(0);
    setProfiles([]);
    setModal(<Loading />);
    getProfiles(filter, currentPage, 10, clientFilter, visibilityFilter, appContext.userData.token).then( response => {
      if( response.status === 200 && response.data && response.data.result && response.data.result.content && Array.isArray(response.data.result.content) ) {
        const profilesTmp = [];
        response.data.result.content.map(p => {
          const client = p.clientId ? catClient.find( c=> c.id === p.clientId ) : {};
          const profile = {
            id: p.id,
            name: p.name,
            clientId: p.clientId,
            clientName: client.optionName,
            client: p.clientId ? { id: p.clientId, description: client.optionName} : selectEmtpy,
            isPublic: p.isPublic,
            visible: appContext.t(`candidate.resume.skills.list.labelVisibility${ p.isPublic ? 'Yes' : 'No' }`)
          }
          profilesTmp.push(profile);
        })
        setProfiles(profilesTmp);
        setTotalPages(response.data.result.totalPages);
        setTotal(response.data.result.totalElements);
        setShowReload(response.data.result.totalElements === 0 || filter !== '' || clientFilter.length > 0 || visibilityFilter.length > 0 );
        setModal(null);
      } else {
        setModal(null);
      }
    }).catch( error => {
      console.log(error);
      setModal(null);
    })
  }

  useEffect(() => {
    loadCatClient();
    loadCatSkills();
  }, [appContext.filter, appContext.clientFilter, appContext.visibilityFilter]);

  const onClickDetail = profile => {
    appContext.contentScrollUp();
    appContext.setMenuNavigation([...appContext.menuNavigation, { label: profile ? defaultStringEmpty(profile.name) : `${appContext.t('button.create')} ${appContext.t('catalogs.profile.title')}`}]);
    if( profile ) {
      loadProfile(profile)
    } else {
      cleanProfile();
    }
    appContext.setHomeContent(<ProfileDetail catClient={ catClient } />)
  }
  
  const onKeyDownSearch = ({key}) => {
    const isEmpty = key === 'Backspace' && appContext.filter.length === 1;
    if ( key === 'Enter' || isEmpty ) {
      setShowReload(!isEmpty);
      onClickSearch(isEmpty ? '' : appContext.filter);
    }
  }

  const onChangeSearch = ({target}) => {
    appContext.setFilter(target.value);
  }
  
  const onClickSearch = (filter) => {
    appContext.setCurrentPage(0);
    fetchProfiles(filter, appContext.clientFilter, appContext.visibilityFilter, 0, catClient);
  }

  const reload = () => {
    setShowReload(false);
    appContext.cleanFilter();
    fetchProfiles('', [], [], 0, catClient);
  }

  const onClickBackPage = () => {
    if( appContext.currentPage > 0 ) {
      appContext.setCurrentPage(appContext.currentPage - 1);
      fetchProfiles(appContext.filter, appContext.clientFilter, appContext.visibilityFilter, appContext.currentPage - 1, catClient);
    }
  }

  const onClickForwardPage = cP => {
    if( appContext.currentPage + cP < totalPages ) {
      appContext.setCurrentPage(appContext.currentPage + 1);
      fetchProfiles(appContext.filter, appContext.clientFilter, appContext.visibilityFilter, appContext.currentPage + 1, catClient);
    }
  }

  const onClickSelectFilter = (option, value, setValue) => {
    const newValue = value.find( v => objEquals(v,option) ) ? value.filter( v => v.id !== option.id ) : [ ...value, option ];
    setValue(newValue);
  }

  const onClickSelectVisibility = (option, value, setValue) => {
    setValue( value.find( v => v.id === option.id ) ? value.filter( v => v.id !== option.id ) : [ option ]);
  }

  const renderSelectsFilter = () => (
    <div className={ styles.container_list_sel }>
      <SelectMultiple
        name='clientFilter'
        value = { appContext.clientFilter }
        setValue={ appContext.setClientFilter }
        options = { catClient }
        placeholder = { appContext.t('candidate.list.labelClientFilter') }
        onChange = { onClickSelectFilter }
      />
      <SelectMultiple
        name='visibilityFilter'
        value={ appContext.visibilityFilter }
        setValue={ appContext.setVisibilityFilter }
        options = {[
          { id: 1, optionName: appContext.t('candidate.resume.skills.list.labelVisibilityOn') },
          { id: 2, optionName: appContext.t('candidate.resume.skills.list.labelVisibilityOff') }
        ]}
        placeholder = { appContext.t(`candidate.resume.skills.list.labelVisibilityFilter`) }
        onChange = { onClickSelectVisibility }
      />
    </div>
  )

  const renderBtext = bText => bText && <label className={ styles.pharagrap_textbold }> {bText}</label>

  const renderNotFound = () => {
    let title = '';
    let subtitle = '';
    let bText = undefined;
    let imgSrc = null;
    if( profiles.length === 0 && appContext.filter === '' ) {
      title = appContext.t('catalogs.profile.notFound')
      subtitle = appContext.t('catalogs.profile.notFoundLabel')
      imgSrc = talentListNotFound;
    } else if (profiles.length === 0 && appContext.filter !== '') {
      title = appContext.t('notMatch.title');
      subtitle = appContext.t('notMatch.subtitle');
      imgSrc = talentListNotMatch;
    }
    return (
      <div className={ styles.not_found }>
        <label>{ title }</label>
        <p className={ `${styles.pharagrap} ${styles.pharagrap_simple}` }>{ subtitle }{ renderBtext(bText) }</p>
        <img src={ imgSrc } alt='' />
      </div>
    )
  }

  const renderItems = () => profiles.map( (profile, index) => (
    <tr key={ index } onClick={ () => onClickDetail(profile) }>
      <td className={ styles.first_child_border }>
        <div className={ styles.td_column }>
          <label className={ styles.name }>{ defaultStringEmpty(profile.name) }</label>
        </div>
      </td>
      <td className={ styles.textleft }>
        <div className={ styles.td_column }>
          <label className={ !(profile.client && profile.client.description) ? styles.fontgray : undefined }>{ defaultStringEmpty(profile.client ? profile.client.description : '') }</label>
        </div>
      </td>
      <td className={ styles.last_child_border }>{ profile.visible }</td>
    </tr>
  ) );

  const renderTable = () => (
    <table>
      <thead>
        <tr>
          <th className={ `${styles.first_child_border} ${styles.textleft}` } style={{ width: '40%' }}>{appContext.t('catalogs.profile.labelProfile')}</th>
          <th className={ styles.textleft } style={{ width: '40%' }}>{appContext.t('candidate.list.headers.client')}</th>
          <th className={ `${styles.last_child_border} ${styles.textleft}` } style={{ width: '20%' }}>{appContext.t('candidate.resume.skills.list.headers.suggestion')}</th>
        </tr>
      </thead>
      <tbody>
        { renderItems() }
      </tbody>
    </table>
  )

  const buildLabelResult = () => {
    const entityString = appContext.t('catalogs.profile.' + ( total === 1 ? 'title' : 'labelTotal' )).toLowerCase();
    let complementString = appContext.filter !== '' || appContext.clientFilter.length > 0 || appContext.visibilityFilter.length > 0 ? appContext.t('candidate.list.labelNoFound') : '';
    if( total === 1 && complementString.endsWith('s') ) {
      complementString = complementString.substring(0, complementString.length-1);
    }
    return `${entityString} ${complementString}`;
  }

  return (
    <div className={ styles.container } >
      <div className={ styles.wrapper }>
        <div className={ styles.container_list_btn }>
          <label className={ styles.title }>{ appContext.t('menu.catalog.opt2') }</label>
          <div className={ styles.buttons }>
            <button onClick={() => onClickDetail(null) }>{ `${appContext.t('button.create')} ${appContext.t('catalogs.profile.title')}` }</button>
          </div>
        </div>
        <div className={ styles.container_list_btn }>
          <div className={ styles.btn_label }>
            <div className={ styles.width_40 }>
              <Searcher 
                name={'filter'} 
                value={ appContext.filter } 
                placeholder={ appContext.t('catalogs.profile.labelFilter') }
                onChange={ onChangeSearch }
                onKeyDown={ onKeyDownSearch }
                onClick={ () => onClickSearch(appContext.filter) }
              />
            </div>
            <label>{ `${total} ${ buildLabelResult() }` }</label>
            { showReload && !modal &&  <div className={ styles.separator } /> }
            {
              showReload && !modal && (
                <div className={ styles.reload } onClick = { () => reload() }>
                  <img src={ reloadIcon } alt='' />
                  <label>{ appContext.t('catalogs.profile.labelReload') }</label>
                </div>
              )
            }
          </div>
        </div>
        { renderSelectsFilter() }
        { renderTable() }
        { !modal && profiles.length === 0 && renderNotFound() }
        { modal }
        <Pagination
          totalElements={ profiles.length }
          currentPage={ appContext.currentPage }
          totalPages={ totalPages }
          onClickBackPage={ onClickBackPage }
          onClickForwardPage={ onClickForwardPage }
        />
      </div>
    </div>
    
  )
}
